import { ErrorException } from './error-exception'

/**
 * codeForCountry function
 * @param {String} country - country of app
 * @returns {String} the code related to the country
 */
const codeForCountry = (country) => {
  try {
    let code = ''
    if (country === 'México') {
      code = 'MX'
    } else if (country === 'Colombia') {
      code = 'CO'
    } else if (country === 'Chile') {
      code = 'CL'
    } else if (country === 'Peru') {
      code = 'PE'
    }
    return code
  } catch (errorCodeCountry) {
    throw new ErrorException(
      errorCodeCountry.message,
      errorCodeCountry.name,
      errorCodeCountry.object
    )
  }
}

export default codeForCountry
