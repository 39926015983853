/* eslint-disable react/no-array-index-key */
import React from 'react'
import ReactToPrint from 'react-to-print'
import styles from './CashTemplate.module.css'
import messages from './CashTemplate.messages'
import SuccessAddress from '../SuccessAddress/SuccessAddress'

/**
 * CashTemplate Component
 * @returns {React.Component} CashTemplate .
 */
const CashTemplate = ({
  baloto,
  ticket,
  refContainer,
  externalUrl,
  category,
  address,
  onChangeValue,
  customerId,
  paymentMethodSelected,
}) => {
  /**
   * Open Url
   * @returns {void}  .
   */
  const openUrl = () => {
    window.open(externalUrl, '_blank')
  }

  const title = messages.title(paymentMethodSelected)
  const arrayReference = ticket && ticket.match(/.{1,4}/g)
  return (
    <div className={styles.CashContainer}>
      <p className={styles.CashReference}>{title}</p>
      {externalUrl ? (
        <div className={styles.FormSubmitButton}>
          <div
            role="button"
            tabIndex={0}
            className={styles.SubmitButton}
            onClick={() => openUrl()}
          >
            {messages.textDownload}
          </div>
        </div>
      ) : (
        <>
          <p className={styles.CashTitle}>{messages.subTitle}</p>

          <div className={styles.RefContainer}>
            {ticket &&
              arrayReference.map((item, index) => (
                <>
                  <div key={index} className={styles.RefItem}>
                    {item}
                  </div>
                </>
              ))}
          </div>

          <p className={styles.Notice}>{messages.scanner}</p>

          <div className={styles.Buttons}>
            <ReactToPrint
              trigger={() => (
                <div className={styles.ButtonDownload}>
                  {messages.textDownload}
                </div>
              )}
              content={() => refContainer.current}
            />
          </div>
        </>
      )}
      <SuccessAddress
        category={category}
        address={address}
        onChangeValue={onChangeValue}
        customerId={customerId}
        paymentMethodSelected={paymentMethodSelected}
      />
    </div>
  )
}

export default CashTemplate
