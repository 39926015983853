/* eslint-disable complexity */
import React, { Component } from 'react'
import style from './Header.module.css'
import logo from '../../Assets/images/svg/moons.svg'
import back from '../../Assets/images/svg/back.svg'
import messages from './Header.messages'
import TimeCounter from '../TimeCounter/TimeCounter'
import PrincipalProduct from '../PrincipalProduct/PrincipalProduct'
import { INITIAL_STEP } from '../../Utils/constants'
import TotalPrice from '../TotalPrice/TotalPrice'
import Coupon from '../Coupon/Coupon'

/**
 * Header Component
 * @returns {void} .
 */
class Header extends Component {
  /**
   * Header constructor
   * @param {Object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    this.state = {}
  }

  extraProductsComponent = () => {
    const { currentExtraProducts, dataExtra, isPaymentLink } = this.props
    const component = []
    for (const index in currentExtraProducts) {
      if ({}.hasOwnProperty.call(currentExtraProducts, index)) {
        const extra = dataExtra.filter(
          (dataExt) => dataExt.keyName === index
        )[0]

        let { quantity } = currentExtraProducts[index]
        while (quantity) {
          component.push(
            <PrincipalProduct
              key={extra.objectId}
              product={extra}
              selected={false}
              setExtraData={this.setExtraData}
              allowRemove
              isPaymentLink={isPaymentLink}
            />
          )
          quantity -= 1
        }
      }
    }
    return component
  }

  /**
   * Set Extra Data
   * @param {String} index .
   * @returns {void} .
   */
  setExtraData = (index) => {
    const { setExtraProducts, currentExtraProducts } = this.props
    const { quantity } = currentExtraProducts[index]
    const newExtra = { keyName: index, quantity: quantity - 1 }
    setExtraProducts && setExtraProducts(newExtra)
  }

  /**
   * Header render
   * @returns {void} .
   */
  render() {
    const {
      right,
      products,
      currentProduct,
      setCurrentProduct,
      currentStep,
      currentExtraProducts,
      finalPrice,
      couponData,
      stepBack,
      pricePrincipalProduct,
      isPaymentLink,
      installmentsData,
      isLiquidation,
      patientName,
      paymentMethodSelected,
      isSixtyForty,
      success,
      error,
      urlCoupon,
      externalPromo,
    } = this.props

    const { priceInstallments } = installmentsData
    const allProducts = products && products.data
    const dataCurrentProduct =
      allProducts &&
      allProducts.filter(
        (prod) =>
          prod.keyName === currentProduct || prod.paymentKey === currentProduct
      )[0]
    const currencyProd = dataCurrentProduct && dataCurrentProduct.currency

    const nameProduct =
      dataCurrentProduct &&
      (dataCurrentProduct.keyName || dataCurrentProduct.paymentKey)
    const lastView = success || error

    const showCoupon =
      !isPaymentLink && couponData && couponData.showCoupon && !lastView

    const showArrow = !lastView && currentStep.name !== INITIAL_STEP

    const logoComponent = (
      <div
        className={[
          style.Logo,
          showArrow ? style.PartWith : style.AllWith,
        ].join(' ')}
      >
        <img alt="logo" src={logo} />
      </div>
    )

    const backComponent = (
      <div
        role="button"
        tabIndex={0}
        className={[style.Back, showArrow ? '' : style.DisplayNone].join(' ')}
        onClick={() => stepBack(true)}
      >
        <img alt="logo" src={back} />
      </div>
    )

    return (
      <div
        className={[style.HeaderContainer, right ? style.Right : ''].join(' ')}
      >
        <div
          className={[
            style.BackLogoContainer,
            lastView ? style.Center : '',
          ].join(' ')}
        >
          {backComponent}
          {logoComponent}
        </div>
        {!success && (
          <TimeCounter
            externalPromo={externalPromo}
            couponData={couponData}
            currencyProd={currencyProd}
          />
        )}
        {currentStep.name === INITIAL_STEP ? (
          <>
            <div className={style.AskProducts}>{messages.titleProduct}</div>
            <div className={style.PrincipalProducts}>
              {Array.isArray(allProducts) &&
                allProducts[0] &&
                allProducts.map((prod) => {
                  const id = prod.keyName || prod.paymentKey
                  return (
                    <PrincipalProduct
                      key={prod.objectId}
                      product={prod}
                      price={pricePrincipalProduct}
                      selectProduct={(keyName) => setCurrentProduct(keyName)}
                      selected={
                        currentProduct === id || allProducts.length === 1
                      }
                      isPaymentLink={isPaymentLink}
                      isLiquidation={isLiquidation}
                      patientName={patientName}
                      installmentsData={installmentsData}
                      paymentMethodSelected={paymentMethodSelected}
                      isSixtyForty={isSixtyForty}
                    />
                  )
                })}
            </div>
          </>
        ) : (
          <div className={style.ProductsToPurchase}>
            <PrincipalProduct
              product={dataCurrentProduct}
              selected={false}
              couponData={couponData}
              price={pricePrincipalProduct}
              isPaymentLink={isPaymentLink}
              isLiquidation={isLiquidation}
              patientName={patientName}
              installmentsData={installmentsData}
              paymentMethodSelected={paymentMethodSelected}
              isSixtyForty={isSixtyForty}
            />
            {currentExtraProducts && this.extraProductsComponent()}
            <div className={style.WrapperPrice}>
              <TotalPrice price={priceInstallments || finalPrice} />
            </div>
            {showCoupon && (
              <Coupon
                couponData={couponData}
                nameProduct={nameProduct}
                urlCoupon={urlCoupon}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

export default Header
