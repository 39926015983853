const messages = {
  title:
    'Acude a la sucursal oxxo más cercana y sigue las siguientes instrucciones.',
  subTitle: 'Dicta estos números al cajero para realizar tu pago',
  scanner:
    'O pasa por el scaner de la tienda el siguiente código de barras (puede ser impreso o desde tu celular)',
  textDownload: 'DESCARGAR FORMA DE PAGO',
  textSend: 'ENVIAR A MI CORREO',
}

export default messages
