/* eslint-disable no-else-return */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable max-params */
/* eslint-disable no-undefined */
/* eslint-disable max-statements */
import md5 from 'md5'
import messages from './Checkout.messages'

/**
 * @param {*} paymentMethodSelected .
 * @return {void}.
 */
const getPaymentButtonTextLabel = (paymentMethodSelected) => {
  switch (paymentMethodSelected) {
    case 'card':
      return messages.CardLabel
    case 'oxxo':
      return messages.OxxoLabel
    case 'spei':
      return messages.BankTransferLabel
    case 'pse':
      return messages.PseLabel
    case 'efecty':
      return messages.EfectyLabel
    case 'baloto':
      return messages.BalotoLabel
    case 'pagoEfectivo':
      return messages.PagoEfectivoLabel
    default:
      return null
  }
}

/**
 * @return {Void} .
 */
const createPayuScripts = () => {
  const sessionId = md5('moons-checkout')
  document.cookie = `session_Id=${sessionId}`
  const microtime = Date.now() * 1000
  const deviceSessionId = md5(`${sessionId}${microtime}`)
  const script = document.createElement('script')
  const noScript = document.createElement('noscript')
  const iframe = document.createElement('iframe')
  iframe.style =
    'width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;'
  iframe.src = `https://maf.pagosonline.net/ws/fp/tags.js?id=${deviceSessionId}80200`
  script.src = `https://maf.pagosonline.net/ws/fp/tags.js?id=${deviceSessionId}80200`
  script.async = true
  noScript.appendChild(iframe)
  script.id = 'script-payu'
  noScript.id = 'noscript-payu'
  document.body.appendChild(script)
  document.body.appendChild(noScript)
  localStorage.setItem('cookie', md5(sessionId))
  localStorage.setItem('sessionId', sessionId)
  localStorage.setItem('deviceSessionId', deviceSessionId)
}

export default {
  getPaymentButtonTextLabel,
  createPayuScripts,
}
