const messages = {
  title:
    'Ingresa el siguiente número de referencia al momento de realizar tu transferencia a tu banca en línea',
  subTitle: 'Ingresa los siguientes datos en el apartado de cuenta de destino',
  clabe: 'CLABE: ',
  bank: 'Banco de destino: ',
  beneficiary: 'Beneficiario: ',
  textDownload: 'DESCARGAR CLABE',
  beneficiaryName: 'Grupo Dental Tecnologico Mexicano SAPI de CV',
  notice:
    'Tu pago tardará de 48 a 72 horas en reflejarse, te enviaremos un mail una vez que esto suceda.',
}

export default messages
