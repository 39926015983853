/* eslint-disable react/jsx-max-props-per-line */

import React from 'react'
import styles from './ProgressSteps.module.css'

/**
 * Circle
 * @param {String} type can be blue or white .
 * @param {Integer} index key.
 * @returns {void} .
 */
const Circle = (type, index) => {
  const keyB = `blue_${index}`
  const keyG = `gray_${index}`

  if (type === 'blue')
    return (
      <div key={keyG} className={[styles.SmallCircle, styles.Blue].join(' ')} />
    )
  if (type === 'white') {
    return (
      <div key={keyB} className={[styles.BigCircle, styles.Blue].join(' ')}>
        <div className={[styles.SmallCircle, styles.White].join(' ')} />
      </div>
    )
  }
  return (
    <div key={keyG} className={[styles.SmallCircle, styles.Gray].join(' ')} />
  )
}

/**
 * Line
 * @param {String} type can be blue or white .
 * @param {Integer} index key.
 * @returns {void} .
 */
const Line = (type, index) => {
  const keyB = `blue_${index}`
  const keyW = `white_${index}`

  if (type === 'blue') {
    return <div key={keyB} className={[styles.Line, styles.Blue].join(' ')} />
  }
  return <div key={keyW} className={[styles.Line, styles.Gray].join(' ')} />
}

/**
 * ProgressSteps
 * @param {String} props .
 * @returns {void} .
 */
const ProgressSteps = (props) => {
  const { totalSteps } = props
  const { currentStep } = props
  const steps = []
  for (let index = 1; index <= totalSteps; index++) {
    if (index === currentStep) steps.push(Circle('white'))
    else if (index < currentStep) {
      steps.push(Circle('blue', index))
      steps.push(Line('blue', index))
    } else {
      steps.push(Circle('gray', index))
    }
    if (index >= currentStep && index < totalSteps) {
      steps.push(Line('gray', index))
    }
  }

  return <div className={styles.Container}>{steps}</div>
}

export default ProgressSteps
