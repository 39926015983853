const messages = {
  advice: 'Tu orden has sido reservada por: ',
  minute: 'minutos',
  by: 'Powered by ',
  stripe: 'Stripe',
  mercadoPago: 'Mercado Pago',
  term: 'Términos y condiciones',
  privacy: 'Aviso de privacidad',
  urlTerm: {
    México: 'https://mymoons.mx/terminos-y-condiciones',
    Colombia: 'https://mymoons.co/terminos-y-condiciones',
  },
  urlPrivacy: {
    México: 'https://mymoons.mx/aviso-de-privacidad',
    Colombia: 'https://mymoons.co/aviso-de-privacidad',
  },
  textLove: {
    MX: 'Hecho con ❤︎ en México',
    CO: 'Hecho con ❤︎ en Colombia',
  },
}

export default messages
