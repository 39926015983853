const messages = {
  subTitle: 'Si tienes dudas, escribenos a ',
  email: 'notifications@mymoons.mx',
  transactionId: 'Código de confirmación: ',
  buy: 'Compraste',
  paymentAmount: 'Monto de pago: ',
  titleAddress1: '¿Dónde enviaremos tus moons cuando esten listos?',
  titleAddress2: 'Confirma la dirección donde enviaremos tus moons',
  save: 'Guardar',
  successText: 'La dirección se ha actualizado correctamente',
  errorText: 'Hubo un error al actualizar la dirección',
}

export default messages
