/* eslint-disable complexity */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-child-element-spacing */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import currency from 'currency.js'
import styles from './SuccessCardTwo.module.css'
import logo from '../../Assets/images/svg/moons.svg'
import messages from './SuccessCardTwo.messages'
import queryString from 'query-string'
import hero from '../../Assets/images/png/hero-banner-referrals@2x.png'
import { chooseCountry } from '../../Utils/utils'
import { getReferralData } from '../../Utils/referrals'
import SuccessAddress from '../SuccessAddress/SuccessAddress'
import { IS_CHILE, IS_PERU } from '../../Utils/constants'

const logoComponent = (
  <div className={styles.Logo}>
    <img alt="logo" src={logo} />
  </div>
)

const countryRefs = {
  México: {
    booking: process.env.REACT_APP_BOOKING_URL_MX,
  },
  Colombia: {
    booking: process.env.REACT_APP_BOOKING_URL_CO,
  },
  Chile: {
    booking: process.env.REACT_APP_BOOKING_URL_CL,
  },
  Peru: {
    booking: process.env.REACT_APP_BOOKING_URL_CL,
  },
}

/**
 * SuccessCardTwo Component
 * @param {Object} response - json to the response
 * @return {void}
 */
class SuccessCardTwo extends React.Component {
  /**
   *
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    let query = {}
    const history = props.history
    let redirectUrl = false
    let buttonUrl = false
    let successUrl = false
    if (history && history.location && history.location.search) {
      query = queryString.parse(history.location.search)
      redirectUrl = query.redirectUrl || false
      buttonUrl = query.buttonUrl || false
      successUrl = query.successUrl || false
    }

    this.state = {
      show: true,
      redirectUrl,
      buttonUrl,
      successUrl,
    }

    this.country = process.env.REACT_APP_COUNTRY
    this.countryCode = chooseCountry(this.country).toLowerCase()
  }

  componentDidMount() {
    const { coupon, patientName, currentProduct } = this.props
    const productKeyName = currentProduct.keyName
    let paymentType = 'payoff'
    if (
      productKeyName &&
      (productKeyName.includes('appointment') ||
        productKeyName.includes('homekit'))
    ) {
      paymentType = 'advance'
    }

    if (this.props.category === 'homekit' && this.props.history) {
      this.props.history.push('?homekit-success=true')
    }

    const { popupData, link } = getReferralData(
      this.countryCode,
      paymentType,
      coupon,
      patientName
    )

    let data = {
      title: popupData.title,
      callToAction: popupData.callToAction,
      description: popupData.description,
      buttonText: popupData.buttonText,
      buttonLink: link,
    }
    const timeout = 2000
    !IS_CHILE && !IS_PERU && window.referralPopUp.showPopUp(data, timeout)
  }

  /**
   * @returns {void}
   */
  render() {
    const {
      response,
      finalPrice,
      installmentsData,
      category,
      address,
      onChangeValue,
      addressError,
      customerId,
      paymentMethodSelected,
      titleProduct,
      priceOld,
      currencyProd,
      country,
      publicKey,
      userDashboard,
    } = this.props

    const countryEnv = process.env.REACT_APP_COUNTRY
    const precision = countryEnv === 'Colombia' || country === 'Chile' || countryEnv === 'Peru' ? 0 : 2
    const separator =
      countryEnv === 'Colombia' || countryEnv === 'Chile' || countryEnv === 'Peru' ? '.' : ','
    const symbol = country === 'Peru' ? 'S/ ' : '$'
    const { priceInstallments } = installmentsData || {}
    const price = `${currency(priceInstallments || finalPrice, {
      precision,
      separator,
      symbol
    }).format()} ${countryEnv === 'Peru' ? '' : currencyProd}`
    const priceOldTemp = `${currency(priceOld, {
      precision,
      separator,
      symbol
    }).format()} ${countryEnv === 'Peru' ? '' : currencyProd}`

    const transactionId =
      response &&
      (response.stripePaymentId ||
        response.conektaPaymentId ||
        response.mercadoPagoPaymentId ||
        response.payuPaymentId)

    if (this.state.redirectUrl) {
      let url = this.state.redirectUrl
      let host = url.split('?').shift()
      let urlSearchParams = new URL(url).searchParams
      if (this.state.buttonUrl && userDashboard) {
        const dashboardUrl = encodeURIComponent(
          `${this.state.buttonUrl}&token=${publicKey}54${publicKey}`
        )
        urlSearchParams.append('redirectUrl', dashboardUrl)
      }
      urlSearchParams.append('transactionId', transactionId)

      url = decodeURIComponent(`${host}?${urlSearchParams.toString()}`)
      window.location.href = url
      return <></>
    }

    if (category == 'appointment') {
      const bookingUrl = countryRefs[country].booking
      let url = `${bookingUrl}/appointment-success/${publicKey}?transactionId=${transactionId}`
      if (userDashboard) {
        const dashboardUrl = encodeURIComponent(
          `${process.env.REACT_APP_DASHBOARD_URL}?customerId=${customerId}&token=${publicKey}54${publicKey}`
        )
        url = `${url}&redirectUrl=${dashboardUrl}`
      }
      window.location.href = url
      return <></>
    }
    let returnButton = <></>
    if (this.state.successUrl) {
      let url = this.state.successUrl
      let urlSearchParams = new URL(url).searchParams
      if (this.state.buttonUrl) {
        urlSearchParams.append('redirectUrl', this.state.buttonUrl)
      }
      urlSearchParams.append('transactionId', transactionId)
    } else if (userDashboard) {
      let url = `${process.env.REACT_APP_DASHBOARD_URL}?customerId=${customerId}&token=${publicKey}54${publicKey}`
      if (category === 'homekit') {
        url = `${url}&state=registered&type=homekit&transactionId=${transactionId}`
      }
      returnButton = (
        <>
          <a href={url} className={styles.ButtonUD}>
            Ir a mi cuenta
          </a>
        </>
      )
    }

    return (
      <div className={styles.SuccessContainer}>
        {logoComponent}
        <img className={styles.HeroSuccess} alt="hero" src={hero} />
        <div className={styles.Wrapper}>
          <div className={styles.Amount}>
            <p className={styles.Item}>
              {messages.paymentAmount}
              {price}
            </p>
          </div>

          {returnButton}

          <p className={styles.Buy}>{messages.buy}</p>
          <div className={styles.CenterItem}>
            <p className={styles.TitleProduct}>{titleProduct}</p>
            <p className={styles.PriceOldTemp}>{priceOldTemp}</p>
            <p className={styles.Price}>{price}</p>
          </div>
          <div className={styles.Transaction} id="confirmation">
            <p>{messages.transactionId}</p>
            <div className={styles.TransactionId}>
              <p>{transactionId}</p>
            </div>
          </div>
          <SuccessAddress
            paymentMethodSelected={paymentMethodSelected}
            category={category}
            address={address}
            onChangeValue={onChangeValue}
            addressError={addressError}
            customerId={customerId}
          />
        </div>
      </div>
    )
  }
}

export default SuccessCardTwo
