const messages = {
  textInstallments: '(Difiérelo a meses con tarjetas participantes) ',
  close: 'X',
  for: 'para',
  financingText: 'Estás pagando el 60% de tu tratamiento',
  devolution:
    '*Incluido: La política de devoluciones de 30 días, sin condiciones.',
}

export default messages
