import React, { Component } from 'react'
import moment from 'moment'
import style from './TimeCounter.module.css'

/**
 * TimeCounter Component
 * @returns {JSX.Element} .
 */
class TimeCounter extends Component {
  /**
   * TimeCounter constructor
   * @returns {void} .
   */
  constructor() {
    super()
    this.state = {
      time: {
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
        expired: false,
      },
    }
  }

  /**
   * Component Did Mount
   * @returns {void} .
   */
  componentDidMount() {
    this.countDown()
  }

  /**
   * Component Will Unmount
   * @returns {void} .
   */
  componentWillUnmount() {
    this.interval && clearInterval(this.interval)
  }

  countDown = () => {
    const diff = 0
    const second = 1000
    const countDownDate = moment(
      this.props.promo().mx.isCurrent
        ? this.props.promo().mx.counter
        : this.props.promo().co.counter
    )
    // Update the count down every 1 second
    this.interval = setInterval(() => {
      const now = moment()
      const distance = countDownDate - now
      const duration = moment.duration(distance - 1000, 'milliseconds')

      let days = duration.days()
      if (days < 10) {
        days = `0${days}`
      }
      let hours = duration.hours()
      if (hours < 10) {
        hours = `0${hours}`
      }
      let minutes = duration.minutes()
      if (minutes < 10) {
        minutes = `0${minutes}`
      }
      let seconds = duration.seconds()
      if (seconds < 10) {
        seconds = `0${seconds}`
      }

      const currentTime = {
        days,
        hours,
        minutes,
        seconds,
      }
      this.setState({ time: currentTime })

      if (distance < diff) {
        clearInterval(this.interval)
        const timeExpired = {
          expired: true,
          days: '00',
          hours: '00',
          minutes: '00',
          seconds: '00',
        }
        this.setState({ time: timeExpired })
      }
    }, second)
  }

  /**
   * TimeCounter render
   * @returns {JSX.Element} .
   */
  render() {
    const { time } = this.state
    const { days, hours, minutes, seconds } = time
    const daysText = 'DÍAS'
    const hoursText = 'HORAS'
    const minutesText = 'MINUTOS'
    const secondsText = 'SEGUNDOS'
    return (
      <div className={style.CounterContainer}>
        <div className={style.Subtitle}>
          <div className={style.wrapper}>
            <div className={style.number}>
              <p className={style.margin}>{days}</p>
            </div>
            <p className={style.dateTexts}>{daysText}</p>
          </div>
          <p className={style.symbol}>:</p>
          <div className={style.wrapper}>
            <div className={style.number}>
              <p className={style.margin}>{hours}</p>
            </div>
            <p className={style.dateTexts}>{hoursText}</p>
          </div>
          <p className={style.symbol}>:</p>
          <div className={style.wrapper}>
            <div className={style.number}>
              <p className={style.margin}>{minutes}</p>
            </div>
            <p className={style.dateTexts}>{minutesText}</p>
          </div>
          <p className={style.symbol}>:</p>
          <div className={style.wrapper}>
            <div className={style.number}>
              <p className={style.margin}>{seconds}</p>
            </div>
            <p className={style.dateTexts}>{secondsText}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default TimeCounter
