import React from 'react'
import styles from './Banner.module.css'
import TimeCounter from '../PromoTimeCounter/TimeCounter'
import Promo from '../Promo/Promo'

/**
 * Banner component.
 * @returns {JSX.Element} .
 */
const Banner = () => {
  const country = process.env.REACT_APP_COUNTRY
  const isCounter =
    (Promo().mx.counter && country === 'México') ||
    (Promo().co.counter && country === 'Colombia')
  return (
    <div
      className={isCounter ? styles.Container : styles.ContainerWithoutCounter}
    >
      <div className={styles.margin}>
        <div className={styles.Wrapper}>
          <div className={styles.textPromo}>
            <p
              className={
                isCounter ? styles.treatment : styles.treatmentWithOutCounter
              }
            >
              {Promo().mx.isCurrent ? Promo().mx.text : Promo().co.text}
            </p>
          </div>
        </div>
        {isCounter && (
          <div className={styles.wrapperTimer}>
            <TimeCounter promo={Promo} />
          </div>
        )}
      </div>
    </div>
  )
}

export default Banner
