import React from 'react'
import styles from './promo.module.css'
import text from './text'

/**
 * Promo Component
 * @returns {Object} .
 */
const Promo = () => {
  const country = process.env.REACT_APP_COUNTRY
  const promoText = (
    <span>
      {text.discount}
      <span className={styles.hot}>{text.hot}</span>
    </span>
  )
  const promo = {
    mx: {
      active: false,
      isCurrent: country === 'Mexico' || country === 'México',
      text: promoText,
      counter: '2021-07-18 24:00:00',
      sticker: null,
    },
    co: {
      active: false,
      isCurrent: country === 'Colombia' || country === 'colombia',
      text: promoText,
      counter: '2021-07-18 24:00:00',
      sticker: null,
    },
  }
  promo.isActive =
    (promo.mx.isCurrent && promo.mx.active) ||
    (promo.co.isCurrent && promo.co.active)
  promo.stickerActive =
    (promo.mx.sticker && country === 'México') ||
    (promo.co.sticker && country === 'Colombia')
  return promo
}

export default Promo
