import { createRef } from 'react'

/**
 * Dynamic Ref
 * @param {Integer} total .
 * @returns {void} .
 */
export const dynamicRef = (total) => {
  const refs = []
  for (let index = 0; index < total; index++) {
    refs[index] = createRef()
  }
  return refs
}

/**
 * Add Class
 * @param {Element} el .
 * @param {String} myClass .
 * @returns {void} .
 */
export const addClass = (el, myClass) => {
  el.classList.add(myClass)
}

/**
 * Remove Class
 * @param {Element} el .
 * @param {String} myClass .
 * @returns {void} .
 */
export const removeClass = (el, myClass) => {
  el.classList.remove(myClass)
}

/**
 * Is param a object ?.
 * @param {string} obj .
 * @returns {void} .
 */
export const isObject = (obj) => typeof obj === 'object' && obj !== null

/**
 * Transform Extra Product
 * @param {Object} currentExtraProducts .
 * @returns {void} .
 */
export const transformExtraProduct = (currentExtraProducts) => {
  const arrayExtraProducts = []
  for (const index in currentExtraProducts) {
    if ({}.hasOwnProperty.call(currentExtraProducts, index)) {
      if (currentExtraProducts[index].quantity > 0) {
        arrayExtraProducts.push({
          productKeyName: index,
          quantity: currentExtraProducts[index].quantity,
        })
      }
    }
  }
  return arrayExtraProducts
}

/**
 * Available Payment By Country
 * @param {String} country .
 * @param {String} payment .
 * @param {String} price .
 * @returns {Boolean} .
 */
export const availablePaymentByCountry = (country, payment, price) => {
  const payments = [
    {
      type: 'card',
      countries: ['México', 'Colombia', 'Chile', 'Peru'],
      maxPrice: Infinity,
      minPrice: 0,
    },
    {
      type: 'spei',
      countries: ['México'],
      maxPrice: 40000,
      minPrice: 0,
    },
    {
      type: 'oxxo',
      countries: ['México'],
      maxPrice: 10000,
      minPrice: 0,
    },
    {
      type: 'pse',
      countries: ['Colombia'],
      maxPrice: Infinity,
      minPrice: 0,
    },
    {
      type: 'efecty',
      countries: ['Colombia'],
      maxPrice: 4000000,
      minPrice: 5000,
    },
    {
      type: 'baloto',
      countries: ['Colombia'],
      maxPrice: 1000000,
      minPrice: 1000,
    },
    {
      type: 'dinnersClub',
      countries: ['Chile'],
      maxPrice: Infinity,
      minPrice: 0,
    },
    {
      type: 'redCompra',
      countries: ['Chile'],
      maxPrice: Infinity,
      minPrice: 0,
    },
    {
      type: 'pagoEfectivo',
      countries: ['Peru'],
      maxPrice: 10000,
      minPrice: 100,
    },
  ]

  const available = payments.filter((item) => {
    const index = item.countries.indexOf(country)
    const priceAllowed = item.maxPrice >= price && item.minPrice <= price
    return item.type === payment && index !== -1 && priceAllowed
  })

  return available.length
}

/**
 * Choose Country
 * @param {String} country .
 * @returns {String} .
 */
export const chooseCountry = (country) => {
  switch (country) {
    case 'Colombia':
      return 'CO'
    case 'Peru':
      return 'PEN'
    case 'Chile':
      return 'CLP'
    default:
      return 'MX'
  }
}

/**
 * Installments Allow
 * @param {String} country .
 * @returns {String} .
 */
export const installmentsAllowed = (country) => {
  switch (country) {
    case 'Colombia':
      return [2, 3, 6, 12]
    default:
      return [3, 6, 9, 12, 18]
  }
}

/**
 * Sets userId in analytics
 * @param {String} customerId .
 * @returns {void} .
 */
export const setAnalyticsUserId = (customerId) => {
  if (customerId && typeof window.ga === 'function') {
    window.ga('set', 'userId', customerId)
  }
}
/**
 * Sends patient to freshpaint
 * @param {Object} patient.
 * @returns {void} .
 */
export const setTrackingPatient = async (patient) => {
  if (patient && patient.CustomerId) {
    let properties = {
      customerId: patient.CustomerId,
    }
    if (patient.Email) {
      properties.email = patient.Email
    }
    if (patient.Phone) {
      properties.phone = patient.Phone
    }
    if (patient.Patient_Name) {
      properties.name = patient.Patient_Name
    }
    if (window.freshpaint) {
      window.localStorage.setItem('CustomerId', patient.CustomerId)
      window.dataLayer.push({'event':'loadPixel'});
      window.freshpaint.identify(patient.CustomerId, properties)
      return true;
    } else {
      return false;
    }
  }

}
