import React from 'react'
import styles from './PaymentSelector2.module.css'
import messages from './PaymentSelector2.messages'
import visa from '../../../Assets/images/svg/visa.svg'
import mastercard from '../../../Assets/images/svg/mastercard.svg'
import paypal from '../../../Assets/images/svg/paypal.svg'
import unionPay from '../../../Assets/images/svg/unionPay.svg'
import americanExpress from '../../../Assets/images/svg/americanExpress.svg'
import applePay from '../../../Assets/images/png/applePay.png'
import efecty from '../../../Assets/images/png/efecty@2x.png'
import baloto from '../../../Assets/images/png/baloto@2x.png'
import { availablePaymentByCountry } from '../../../Utils/utils'

/**
 * PaymentSelector2 Component
 * @returns {React.Component} PaymentSelector2 .
 */
const PaymentSelector2 = ({ country, finalPrice, onChangePayment }) => {
  const position = 0

  const showExpress =
    messages.expressMethods.filter((item) => item.enable === true).length > 0

  /**
   * Select Img
   * @param {string} payment .
   * @returns {React.Component} Reference .
   */
  const selectImg = (payment) => {
    switch (payment.type) {
      case 'visa':
        return visa
      case 'mastercard':
        return mastercard
      case 'paypal':
        return paypal
      case 'unionPay':
        return unionPay
      case 'applePay':
        return applePay
      case 'americanExpress':
        return americanExpress
      case 'efecty':
        return efecty
      case 'baloto':
        return baloto
      default:
        return ''
    }
  }
  window.scrollTo(position, position)
  return (
    <div className={styles.SelectorContainer}>
      {showExpress && (
        <div className={styles.ExpressContainer}>
          <div className={styles.TitleExpress}>{messages.titleExpress}</div>
          {messages.expressMethods.map((item) => {
            if (item.enable) {
              return (
                <div
                  key={item.id}
                  role="button"
                  tabIndex={0}
                  className={[
                    styles.Button,
                    item.value === 'applePay'
                      ? styles.AppleButton
                      : styles.PaypalButton,
                  ].join(' ')}
                  onClick={() => {} /* onChangePayment(item.value) */}
                />
              )
            }
            return ''
          })}
        </div>
      )}
      <div className={styles.TraditionalContainer}>
        <div className={styles.TitleTraditional}>
          {messages.titleTraditional}
        </div>
        <div className={styles.PaymentMethosImg}>
          {messages.imgPayments.map((payment) => {
            const image = selectImg(payment)
            if (payment.enable) {
              return (
                <img
                  key={payment.id}
                  className={styles.Image}
                  alt="payments"
                  src={image}
                />
              )
            }
            return ''
          })}
        </div>
        {messages.traditionalMethods.map((item) => {
          if (availablePaymentByCountry(country, item.value, finalPrice)) {
            return (
              <div
                key={item.id}
                role="button"
                tabIndex={0}
                className={styles.TraditionalButton}
                onClick={() => onChangePayment(item.value)}
              >
                {item.text}
              </div>
            )
          }
          return ''
        })}
      </div>
    </div>
  )
}

export default PaymentSelector2
