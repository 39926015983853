import React from 'react'
import { withRouter } from 'react-router-dom'
import Checkout from '../Checkout/Checkout'

/**
 * CheckoutOrganic Component
 * @returns {void} .
 */
class CheckoutOrganic extends React.Component {
  /**
   * CheckoutFromPaymentId constructor
   * @param {Object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    const {
      match: { params },
    } = props
    this.coupon = params.coupon
  }

  /**
   * CheckoutFromPaymentId render
   * @returns {void} .
   */
  render() {
    const {
      productsKeyName,
      isAdvance,
      showPersonalData,
      showAddress,
      showCoupon,
      extraProductsAllowed,
      showSend,
      selector,
    } = this.props

    return (
      <Checkout
        productsKeyName={productsKeyName}
        isAdvance={isAdvance}
        showPersonalData={showPersonalData}
        showAddress={showAddress}
        showCoupon={showCoupon}
        extraProductsAllowed={extraProductsAllowed}
        showSend={showSend}
        selector={selector}
        urlCoupon={this.coupon}
        isReferralFlow
      />
    )
  }
}

export default withRouter(CheckoutOrganic)
