const messages = {
  CO: {
    dniData: [
      {
        id: 0,
        text: 'Tipo de Documento',
        value: '',
      },
      {
        id: 1,
        text: 'Cédula de Ciudadanía',
        value: 'CC',
      },
      {
        id: 2,
        text: 'Cédula de Extranjeria',
        value: 'CE',
      },
      {
        id: 3,
        text: 'Número de Identificación Tributaria',
        value: 'NIT',
      },
      {
        id: 4,
        text: 'Otro',
        value: 'Otro',
      },
    ],
  },
  CL: {
    dniData: [
      {
        id: 0,
        text: 'Tipo de Documento',
        value: '',
      },
      {
        id: 1,
        text: 'RUT',
        value: 'RUT',
      },
      {
        id: 2,
        text: 'Otro',
        value: 'Otro',
      },
    ],
  },
  PE: {
    dniData: [
      {
        id: 0,
        text: 'Tipo de Documento',
        value: '',
      },
      {
        id: 1,
        text: 'DNI',
        value: 'DNI',
      },
      // {
      //   id: 2,
      //   text: 'C.E',
      //   value: 'C.E',
      // },
      // {
      //   id: 3,
      //   text: 'RUC',
      //   value: 'RUC',
      // },
      // {
      //   id: 4,
      //   text: 'Otro',
      //   value: 'Otro',
      // },
    ],
  },
  textInstallments: {
    CO: 'Para diferir a cuotas con tarjeta de crédito ingresa el número de tu plastico para cononcer las opciones que tu banco tiene para ti.',
    CL: 'Para diferir a cuotas con tarjeta de crédito ingresa el número de tu plastico para cononcer las opciones que tu banco tiene para ti.',
    PE: 'Para diferir a cuotas con tarjeta de crédito ingresa el número de tu plastico para cononcer las opciones que tu banco tiene para ti.',
    MX: 'Para diferir a meses con tarjeta de crédito ingresa el número de tu plastico para cononcer las opciones que tu banco tiene para ti.',
  },
  devolution:
    '*Incluido: La política de devoluciones de 30 días, sin condiciones.',
}

export default messages
