import React from 'react'
import style from './AddressSaved.module.css'
import messages from './AddressSaved.messages'

/**
 * AddressSaved Component
 * @returns {void} .
 */
const AddressSaved = ({ address, category }) => {
  const textAddress =
    address &&
    `${address.street}, ${address.addressNumber}, ${address.colony}, ${address.zipCode}, ${address.city} `
  return (
    <div className={style.AddressContainer}>
      <p className={style.Title}>
        {category === 'appointment'
          ? messages.appointmentTitle
          : messages.title}
      </p>
      <p className={style.Address}>{textAddress}</p>
    </div>
  )
}

export default AddressSaved
