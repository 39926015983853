/* eslint-disable react/no-deprecated */
/* eslint-disable camelcase */
import React, { Component } from 'react'
import styles from './InputCounter.module.css'
import messages from './InputCounter.messages'

/**
 * InputCounter component.
 * @returns {void} .
 */
class InputCounter extends Component {
  /**
   * Constructor.
   * @param {object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    this.state = {
      counter: props.default,
    }
  }

  /**
   * Component Will Receive Props.
   * @param {object} nextProps .
   * @returns {void} .
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.default !== this.props.default) {
      this.setState({ counter: nextProps.default })
    }
  }

  /**
   * On Change
   * @param {String} key .
   * @param {String} value .
   * @returns {void} .
   */
  onChange = (key, value) => {
    const { setQuantity, keyName } = this.props
    const obj = []
    obj[key] = value
    this.setState(obj)
    setQuantity({ keyName, quantity: value })
  }

  /**
   * InputCounter render
   * @returns {void} .
   */
  render() {
    const { counter } = this.state
    const counterInteger = parseInt(counter, 10)
    return (
      <div className={styles.ContainerCounter}>
        <div
          className={styles.FormCounterButtom}
          role="button"
          onClick={() => {
            counter && this.onChange('counter', counterInteger - 1)
          }}
          tabIndex="0"
        >
          {messages.minus}
        </div>
        <input
          type="number"
          className={styles.FormCounter}
          value={counter}
          onChange={(event) => this.onChange('counter', event.target.value)}
        />
        <div
          className={styles.FormCounterButtom}
          role="button"
          onClick={() => this.onChange('counter', counterInteger + 1)}
          tabIndex="0"
        >
          {messages.more}
        </div>
      </div>
    )
  }
}

export default InputCounter
