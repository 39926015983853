import React, { Component, createRef } from 'react'
import currency from 'currency.js'
import styles from './InstallmentsTwo.module.css'
import messages from './InstallmentsTwo.messages'
import arrow from '../../Assets/images/svg/arrow_down.svg'

/**
 * InstallmentsTwo Component
 * @returns {React.Component} InstallmentsTwo .
 */
class InstallmentsTwo extends Component {
  /**
   * Constructor.
   * @param {object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)

    // this.discountTotal = props.finalPrice * (1 - props.discount / 100)
    this.discountTotal =
      (props.finalPrice * 100) / (100 - props.discount) || props.finalPrice

    const { currencyProd } = props
    const country = process.env.REACT_APP_COUNTRY
    this.precision = country === 'Colombia' || country === 'Chile' || country === 'Peru' ? 0 : 2
    this.separator = country === 'Colombia' || country === 'Chile' || country === 'Peru' ? '.' : ','
    this.symbol = country === 'Peru' ? 'S/ ' : '$'

    this.state = {
      openDropDown: false,
      currentTopic: {
        text: `Un solo pago de ${currency(props.finalPrice, {
          precision: this.precision,
          separator: this.separator,
          symbol: this.symbol
        }).format()} ${country === 'Peru' ? '' : currencyProd}`,
        id: 0,
      },
    }

    this.refImg = createRef(null)
  }

  OpenDropDown = () => {
    const open = !this.state.openDropDown
    this.refImg.current.style.transform = `rotate(${open ? 0 : 180}deg)`
    this.setState({ openDropDown: open })
  }

  /**
   * Component Did Update.
   * @param {object} prevProps .
   * @returns {void} .
   */
  componentDidUpdate = (prevProps) => {
    const { finalPrice } = this.props
    const { currentTopic } = this.state
    if (prevProps.finalPrice !== finalPrice) {
      messages.installmentsType.forEach((item) => {
        if (item.id === currentTopic.id) {
          const { title } = this.calculateValues(item)
          this.changeValue(item, title)
        }
      })
    }
  }

  componentDidMount = () => {
    const { monthSelected } = this.props

    messages.installmentsType.forEach((item) => {
      if (item.value === monthSelected) {
        const { title } = this.calculateValues(item)
        this.changeValue(item, title)
      }
    })
  }

  /**
   * Calculate Values
   * @param {object} item .
   * @returns {void} .
   */
  calculateValues = (item) => {
    const { finalPrice, discount, isAdvance } = this.props
    this.discountTotal = isAdvance
      ? finalPrice
      : (finalPrice * 100) / (100 - discount) || finalPrice
    const cantXMonth = item.value
      ? currency(this.discountTotal, {
          precision: this.precision,
          separator: this.separator,
        }).format()
      : 0
    const title = item.value
      ? item.text.replace('{price}', `$${cantXMonth}`)
      : item.text.replace(
          '{priceTotal}',
          `${currency(finalPrice, {
            precision: this.precision,
            separator: this.separator,
            symbol: this.symbol
          }).format()}`
        )

    return { cantXMonth, title }
  }

  /**
   * Change value selector
   * @param {object} item .
   * @param {object} title .
   * @returns {React.Component} Reference .
   */
  changeValue = (item, title) => {
    const country = process.env.REACT_APP_COUNTRY
    const { currencyProd } = this.props
    const titleFinal = title.replace('{currency}', country === 'Peru' ? '' : currencyProd)
    this.setState({ currentTopic: { text: titleFinal, id: item.id } })
    this.props.setInstallments(item.value)
  }

  /**
   * Render.
   * @param {string} id .
   * @returns {void} .
   */
  render() {
    const country = process.env.REACT_APP_COUNTRY
    const { openDropDown, currentTopic } = this.state
    const { currencyProd } = this.props
    const selector = (
      <div className={styles.ThemesMobileContainer}>
        <div
          className={styles.TopicSelector}
          onClick={() => this.OpenDropDown()}
          role="button"
          tabIndex="0"
          id="installments-selector-two"
        >
          <div className={styles.TextTema}>
            {currentTopic.text}
            <img
              ref={this.refImg}
              alt="flecha"
              src={arrow}
              style={{ transform: `rotate(${openDropDown ? 0 : 180}deg)` }}
            />
          </div>
          {openDropDown && (
            <ul>
              {messages.installmentsType.map((item) => {

                if (process.env.REACT_APP_COUNTRY === 'Peru' && item.value > 12){
                  return <></>
                }

                const { title } = this.calculateValues(item)
                let titleFinal = title.replace('{currency}', country === 'Peru' ? '' : currencyProd)
                if (process.env.REACT_APP_COUNTRY === 'Colombia') {
                  titleFinal = titleFinal.replace(
                    'meses sin intereses',
                    'cuotas'
                  )
                }
                return (
                  <li
                    key={item.id}
                    className={styles.Items}
                    onClick={() => this.changeValue(item, title)}
                    role="menuitem"
                  >
                    {titleFinal}
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
    )

    return <div className={styles.InstallmentsContainer}>{selector}</div>
  }
}

export default InstallmentsTwo
