import React from 'react'
import Banner from '../Banner/Banner'
import styles from './Shell.module.css'

/**
 * Shell component.
 * @returns {void} .
 */
const Shell = ({ children, banner }) => (
  <div className={styles.Container}>
    {banner && <Banner />}
    {children}
  </div>
)

export default Shell
