import React from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'querystring'
import style from './PSEResponse.module.css'
import logo from '../../Assets/images/svg/moons.svg'
import messages from './PSEResponse.messages'
import products from '../../Utils/products'
import TagManager from '../../Utils/tagManager'

const logoComponent = (
  <div className={style.Logo}>
    <img alt="logo" src={logo} />
  </div>
)

/**
 * PSEResponse Component
 * @param {Object} response - json to the response
 * @return {void}
 */
class PSEResponse extends React.Component {
  /**
   *
   * @param {*} props .
   */
  constructor(props) {
    super(props)

    const country = process.env.REACT_APP_COUNTRY
    this.countryCode = country === 'México' ? 'MX' : 'CO'

    this.productInfo = window.innerWidth < 700
    const { location, match } = this.props
    let { search } = location
    search = search.substring(1)
    this.queryVariables = queryString.decode(search)
    const extraString = this.queryVariables.extra1
    this.name = match.params.name ? match.params.name : ''
    if (extraString) {
      this.extras = JSON.parse(extraString)
      if (this.extras.nameClient) {
        this.name = this.extras.nameClient
      }
    }

    this.tagManager = new TagManager()
  }

  /**
   * Component Did Mount .
   * @return {Void} .
   */
  componentDidMount() {
    const result = this.queryVariables.lapTransactionState
    if (result === 'DECLINED') {
      this.tagManager.ErrorEvent('pse')
    } else {
      this.tagManager.SuccessEvent('pse')
    }
  }

  /**
   * @param {Event} event .
   * @return {Void} .
   */
  handleTryButton = (event) => {
    event.preventDefault()
    const { paymentId, productKeyName, paymentLinkKey } = this.extras
    const { history } = this.props
    const productsCurrent = products[this.countryCode]
    const product = Object.keys(productsCurrent).find(
      (key) => productsCurrent[key] === productKeyName
    )
    if (product && paymentId) {
      history.push(`/checkout/${product}/${paymentId}`)
    } else if (paymentLinkKey) {
      history.push(`/checkout/paymentLink/${paymentLinkKey}`)
    } else {
      history.push('/')
    }
  }

  /**
   * @returns {void}
   */
  render() {
    const position = 0
    window.scrollTo(position, position)
    let title = messages.title.replace('{name}', this.name)
    let button = null
    const result = this.queryVariables.lapTransactionState
    if (result === 'DECLINED') {
      title = messages.titleError.replace('{name}', this.name)
      button = (
        <button
          type="button"
          className={style.TryAgain}
          onClick={this.handleTryButton}
        >
          {messages.buttonText}
        </button>
      )
    }

    return (
      <div className={style.SuccessContainer}>
        {logoComponent}
        <h1>{title}</h1>
        <h2>{messages.subTitle}</h2>
        <h2 className={style.Blue}>{messages.email}</h2>
        {button}
      </div>
    )
  }
}

export default withRouter(PSEResponse)
