const messages = {
  Installments_MX_Complex: [
    {
      id: 0,
      text: 'Un solo pago de {priceTotal}',
      value: 0,
      percent: 0,
    },
    {
      id: 1,
      text: '3 pagos de {price}',
      value: 3,
      percent: 0.0297,
    },
    {
      id: 2,
      text: '6 pagos de {price}',
      value: 6,
      percent: 0.0549,
    },
    {
      id: 3,
      text: '9 pagos de {price}',
      value: 9,
      percent: 0.0925,
    },
    {
      id: 4,
      text: '12 pagos de {price}',
      value: 12,
      percent: 0.1253,
    },
    {
      id: 5,
      text: '18 pagos de {price}',
      value: 18,
      percent: 0.168,
    },
    {
      id: 6,
      text: '24 pagos de {price}',
      value: 24,
      percent: 0.2421,
    },
  ],
  Installments_MX: [
    {
      id: 0,
      text: 'Un solo pago de {priceTotal}',
      value: 0,
      percent: 0,
    },
    {
      id: 1,
      text: '3 pagos de {price}',
      value: 3,
      percent: 0.0297,
    },
    {
      id: 2,
      text: '6 pagos de {price}',
      value: 6,
      percent: 0.0549,
    },
    {
      id: 3,
      text: '9 pagos de {price}',
      value: 9,
      percent: 0.0925,
    },
    {
      id: 4,
      text: '12 pagos de {price}',
      value: 12,
      percent: 0.1253,
    },
    {
      id: 5,
      text: '18 pagos de {price}',
      value: 18,
      percent: 0.168,
    },
    {
      id: 6,
      text: '24 pagos de {price}',
      value: 24,
      percent: 0.2421,
    },
  ],
  Installments_CO: [
    {
      id: 0,
      text: 'Un solo pago de {priceTotal}',
      value: 0,
      percent: 0,
    },
    {
      id: 1,
      text: '3 pagos de {price}',
      value: 3,
      percent: 0.05,
    },
    {
      id: 2,
      text: '6 pagos de {price}',
      value: 6,
      percent: 0.075,
    },
    {
      id: 3,
      text: '12 pagos de {price}',
      value: 12,
      percent: 0.125,
    },
  ],
  Installments_CL: [
    {
      id: 0,
      text: 'Un solo pago de {priceTotal}',
      value: 0,
      percent: 0,
    },
    {
      id: 1,
      text: '3 pagos de {price}',
      value: 3,
      percent: 0.05,
    },
    {
      id: 2,
      text: '6 pagos de {price}',
      value: 6,
      percent: 0.075,
    },
    {
      id: 3,
      text: '12 pagos de {price}',
      value: 12,
      percent: 0.125,
    },
  ],
  Installments_PE: [
    {
      id: 0,
      text: 'Un solo pago de {priceTotal}',
      value: 0,
      percent: 0,
    },
    {
      id: 1,
      text: '3 pagos de {price}',
      value: 3,
      percent: 0.0366,
    },
    {
      id: 2,
      text: '6 pagos de {price}',
      value: 6,
      percent: 0.0693,
    },
    {
      id: 3,
      text: '9 pagos de {price}',
      value: 9,
      percent: 0.1041,
    },
    {
      id: 4,
      text: '12 pagos de {price}',
      value: 12,
      percent: 0.1413,
    }
  ]
}

export default messages
