const products = {
  MX: {
    homekit: 'homekit-mx',
    cita: 'appointment-mx',
    cita99: 'appointment-mx',
    cita150: 'appointment-mx',
    simple: 'simple-mx',
    moderado: 'moderado-mx',
    complejo: 'complejo-mx',
    vip: 'vip-mx',
    liquidacion: 'liquidacion-mx',
    moonlight: 'moonlight-mx',
    retainers: 'retainers-mx',
    blanqueamiento: 'blanqueamiento-mx',
    carillas: 'carillas-mx',
    limpieza: 'limpieza-mx',
    implantes: 'implantes-mx',
    enganche4043: 'enganche-4043',
    enganche4016: 'enganche-4016',
    enganche4002: 'enganche-4002',
    enganche3986: 'enganche-3986',
    enganche7516: 'enganche-7516',
    enganche7500: 'enganche-7500',
    enganche7496: 'enganche-7496',
    enganche7459: 'enganche-7459',
    warrantySingleFixed5Years: 'fixed-1-retainer-5-years-mx',
    warrantyDoubleFixed5Years: 'fixed-2-retainers-5-years-mx',
    warrantySingleFixed1Year: 'fixed-1-retainer-1-year-mx',
    warrantyDoubleFixed1Year: 'fixed-2-retainers-1-year-mx',
  },
  CO: {
    homekit: 'homekit-co',
    cita: 'appointment-co',
    cita25000: 'appointment-co',
    citaReducido: 'appointment-co',
    simple: 'simple-co',
    moderado: 'moderado-co',
    complejo: 'complejo-co',
    liquidacion: 'liquidacion-co',
    moonlight: 'moonlight-co',
    retainers: 'retainers-co',
    enganche1M: 'enganche-1M',
    'homekit-promo': 'homekit-promo',
    blanqueamiento: 'blanqueamiento-co',
    carillas: 'carillas-co',
    limpieza: 'limpieza-co',
    implantes: 'implantes-co',
    warrantySingleFixed5Years: 'fixed-1-retainer-5-years-co',
    warrantyDoubleFixed5Years: 'fixed-2-retainers-5-years-co',
    warrantySingleFixed1Year: 'fixed-1-retainer-1-year-co',
    warrantyDoubleFixed1Year: 'fixed-2-retainers-1-year-co',
  },
  CL: {
    cita: 'appointment-cl',
    cita7000: 'appointment-cl-7000',
    cita10900:'appointment-cl-10900',
    citaLead: 'appointment-lead-cl',
    simple: 'simple-cl',
    moderado: 'moderado-cl',
    complejo: 'complejo-cl',
    liquidacion: 'liquidacion-cl',
    warrantySingleFixed5Years: 'fixed-1-retainer-5-years-cl',
    warrantyDoubleFixed5Years: 'fixed-2-retainers-5-years-cl',
    warrantySingleFixed1Year: 'fixed-1-retainer-1-year-cl',
    warrantyDoubleFixed1Year: 'fixed-2-retainers-1-year-cl',
  },
  PE: {
    cita: 'appointment-pe',
    cita19: 'appointment-pe',
    cita29: 'appointment-pe',
    citaLead: 'appointment-lead-pe',
    simple: 'simple-pe',
    moderado: 'moderado-pe',
    complejo: 'complejo-pe',
    liquidacion: 'liquidacion-pe',
    warrantySingleFixed5Years: 'fixed-1-retainer-5-years-pe',
    warrantyDoubleFixed5Years: 'fixed-2-retainers-5-years-pe',
    warrantySingleFixed1Year: 'fixed-1-retainer-1-year-pe',
    warrantyDoubleFixed1Year: 'fixed-2-retainers-1-year-pe',
    planning: 'planning-pe',
  },
}

export default products
