/* eslint-disable max-len */
import React, { Component, createRef } from 'react'
import currency from 'currency.js'
import styles from './Installments.module.css'
import messages from './Installments.messages'
import arrow from '../../Assets/images/svg/arrow_down.svg'
import codeForCountry from '../../Utils/code-country'

/**
 * Installments Component
 * @returns {React.Component} Installments .
 */
class Installments extends Component {
  /**
   * Constructor.
   * @param {object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)

    const { currencyProd, currentProduct } = props

    const country = process.env.REACT_APP_COUNTRY
    this.precision = country === 'Colombia' || country === 'Chile' || country == 'Peru' ? 0 : 2
    this.separator = country === 'Colombia' || country === 'Chile' || country == 'Peru' ? '.' : ','

    this.state = {
      openDropDown: false,
      keyName: currentProduct.keyName,
      months: [],
      currentTopic: {
        id: 0,
        text: `Un solo pago de ${currency(props.price, {
          precision: this.precision,
          separator: this.separator,
        }).format()} ${country === 'Peru' ? '' : currencyProd}`,
      },
    }

    this.refImg = createRef(null)
  }

  /**
   * Component Did Update.
   * @param {object} prevProps .
   * @returns {void} .
   */
  componentDidUpdate = (prevProps) => {
    const { price, monthsAllowed } = this.props
    const { currentTopic, months } = this.state
    if (prevProps.price !== price) {
      months.forEach((item) => {
        if (item.id === currentTopic.id) {
          const { title, totalPrice } = this.calculateValues(item, price)
          this.changeValue(item, title, totalPrice)
        }
      })
    }
    if (prevProps.monthsAllowed !== monthsAllowed) {
      this.updateMonthAllowed()
    }
  }

  componentDidMount = () => {
    const { price, monthSelected } = this.props
    const { months } = this.state

    this.countryCode = codeForCountry(process.env.REACT_APP_COUNTRY)
    this.updateMonthAllowed()

    months.forEach((item) => {
      if (item.value === monthSelected) {
        const { title, totalPrice } = this.calculateValues(item, price)
        this.changeValue(item, title, totalPrice)
      }
    })
  }

  updateMonthAllowed = () => {
    let labelMonths = `Installments_${this.countryCode}`
    if (!messages[labelMonths]) {
      labelMonths = 'Installments_MX'
    }

    /*
     * const monthsNew = messages[labelMonths].filter(item => monthsAllowed
     *             && monthsAllowed.indexOf(item.value) !== -1)
     */

    const keyName = this.state.keyName

    let monthsNew = messages[labelMonths]

    if (keyName === 'complejo-mx') {
      monthsNew = messages['Installments_MX_Complex']
    }

    this.setState({ months: monthsNew })
  }

  OpenDropDown = () => {
    const { openDropDown } = this.state
    this.refImg.current.style.transform = `rotate(${openDropDown ? 180 : 0}deg)`
    this.setState({ openDropDown: !openDropDown })
  }

  /**
   * Change value selector
   * @param {object} item .
   * @param {object} title .
   * @param {Integer} totalPrice .
   * @returns {void} .
   */
  changeValue = (item, title, totalPrice) => {
    const country = process.env.REACT_APP_COUNTRY
    const { currencyProd } = this.props
    const titleFinal = `${title} ${country === 'Peru' ? '' : currencyProd}`

    this.setState({ currentTopic: { text: titleFinal, id: item.id } })
    this.props.setInstallments(item.value, totalPrice)
  }

  /**
   * Calculate Values
   * @param {object} item .
   * @param {object} price .
   * @returns {void} .
   */
  calculateValues = (item, price) => {
    const totalPrice = price * (1 + item.percent)
    const cantXMonth = item.value
      ? currency(Math.round(totalPrice / item.value), {
          precision: this.precision,
          separator: this.separator,
        }).format()
      : 0
    const title = item.value
      ? item.text.replace('{price}', `$${cantXMonth}`)
      : item.text.replace(
          '{priceTotal}',
          `${currency(price, {
            precision: this.precision,
            separator: this.separator,
          }).format()}`
        )

    return { totalPrice, cantXMonth, title }
  }

  /**
   * Render.
   * @param {string} id .
   * @returns {void} .
   */
  render() {
    const { openDropDown, currentTopic, months } = this.state
    const { price, currencyProd } = this.props
    const country = process.env.REACT_APP_COUNTRY

    const selector = (
      <div className={styles.ThemesMobileContainer}>
        <div
          className={styles.TopicSelector}
          onClick={() => this.OpenDropDown()}
          role="button"
          tabIndex="0"
          id="installments-selector"
        >
          <div className={styles.TextTema}>
            {currentTopic.text}
            <img
              ref={this.refImg}
              alt="arrow"
              src={arrow}
              style={{ transform: `rotate(${openDropDown ? 0 : 180}deg)` }}
            />
          </div>
          {openDropDown && (
            <ul>
              {months.map((item) => {
                const { title, totalPrice } = this.calculateValues(item, price)
                const titleFinal = `${title} ${country === 'Peru' ? '' : currencyProd}`
                return (
                  <li
                    key={item.id}
                    className={styles.Items}
                    onClick={() => this.changeValue(item, title, totalPrice)}
                    role="menuitem"
                  >
                    {titleFinal}
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
    )

    return <div className={styles.InstallmentsContainer}>{selector}</div>
  }
}

export default Installments
