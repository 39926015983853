const messages = {
  advice: 'Hemos apartado tu cita por:',
  expired: 'Se agoto el tiempo, por favor selecciona otro horario',
  popupTitle: 'Tiempo agotado',
  popupDesc:
    'Debido al alto volumen de solicitudes, no podemos apartar tu selección por mucho tiempo. Es necesario que selecciones nuevamente la fecha y la hora para tu cita.',
  popupBtn: 'Seleccionar fecha y hora',
}

export default messages
