import { PERSONAL_DATA_STEP, ADDRESS_STEP, PAYMENTS_STEP } from './constants'

/**
 * Tag Manager Class.
 * @param {string} country .
 * @returns {void} .
 */
class TagManager {
  /**
   * Constructor.
   * @param {string} country .
   * @returns {void} .
   */
  constructor() {
    const country = process.env.REACT_APP_COUNTRY

    this.tag = '_mx'
    if (country === 'Colombia') {
      this.tag = '_co'
    }
  }

  /**
   * Init Event.
   * @param {string} country .
   * @returns {void} .
   */
  InitEvent = () => {
    window.dataLayer.push({ event: `init_checkout${this.tag}` })
  }

  /**
   * Steps Event.
   * @param {String} step .
   * @param {String} showPersonalData .
   * @param {String} showAddress .
   * @returns {void} .
   */
  StepsEvent = (step, showPersonalData, showAddress) => {
    switch (step) {
      case PERSONAL_DATA_STEP:
        if (showPersonalData)
          window.dataLayer.push({ event: `personal_data_checkout${this.tag}` })
        break
      case ADDRESS_STEP:
        if (showAddress)
          window.dataLayer.push({ event: `address_checkout${this.tag}` })
        break
      case PAYMENTS_STEP:
        window.dataLayer.push({ event: `payment_checkout${this.tag}` })
        break
      default:
        break
    }
  }

  /**
   * Success Event.
   * @param {String} paymentMethods .
   * @returns {void} .
   */
  SuccessEvent = (paymentMethods) => {
    switch (paymentMethods) {
      case 'oxxo':
        window.dataLayer.push({ event: `oxxo_reference${this.tag}` })
        break
      case 'spei':
        window.dataLayer.push({ event: `spei_reference${this.tag}` })
        break
      case 'pse':
        window.dataLayer.push({ event: `pse_success${this.tag}` })
        break
      case 'card':
        window.dataLayer.push({ event: `card_success${this.tag}` })
        break
      default:
        break
    }
  }

  /**
   * Error Event.
   * @param {String} paymentMethods .
   * @returns {void} .
   */
  ErrorEvent = (paymentMethods) => {
    switch (paymentMethods) {
      case 'pse':
        window.dataLayer.push({ event: `pse_failure${this.tag}` })
        break
      case 'card':
        window.dataLayer.push({ event: `card_failure${this.tag}` })
        break
      default:
        break
    }
  }

  /**
   * Coupon Event.
   * @returns {void} .
   */
  CouponEvent = () => {
    window.dataLayer.push({ event: `coupon_checkout${this.tag}` })
  }

  /**
   * Copy Coupon.
   * @param {string} country .
   * @returns {void} .
   */
  copyCoupon = () => {
    window.dataLayer.push({ event: 'REF_CODE_COPIED' })
  }

  /**
   * Share coupon whatsapp.
   * @param {string} country .
   * @returns {void} .
   */
  shareCouponWhatsApp = () => {
    window.dataLayer.push({ event: 'REF_CODE_WHATSAPP' })
  }

  /**
   * Share coupon facebook.
   * @param {string} country .
   * @returns {void} .
   */
  shareCouponFace = () => {
    window.dataLayer.push({ event: 'REF_CODE_FB' })
  }
}

export default TagManager
