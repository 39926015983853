const messages = {
  advice: 'Tu promoción es valida solo hasta el:',
  advice2: 'Tu promoción es válida por tiempo limitado',
  CouponPercentage:
    'Te descontaremos el {discount}% de tu tratamiento cuando liquides',
  CouponNormal:
    'Te descontaremos {discount} {currency} de tu tratamiento cuando liquides',
}

export default messages
