const messages = {
  subTitle: 'Si tienes dudas, escribenos a ',
  email: 'notifications@mymoons.mx',
  transactionId: 'Confirmación: ',
  paymentAmount: 'Monto de pago: ',
  titleAddress1: '¿Dónde enviaremos tus moons cuando esten listos?',
  titleAddress2: 'Confirma la dirección donde enviaremos tus moons',
  save: 'Guardar',
  successText: '¡Gracias por confirmar tu dirección!',
  errorText: 'Hubo un error al actualizar la dirección',
  street: 'Calle',
  internalNumber: 'Número Interior',
  CO: {
    colony: 'Barrio',
    state: 'Departamento',
    city: 'Municipio',
    addressNumber: 'Número',
  },
  CL: {
    // There isn't colony in Chile for the moment
    colony: 'TBD',
    state: 'Región',
    city: 'Comuna',
    addressNumber: 'Número Exterior',
  },
  MX: {
    colony: 'Colonia',
    state: 'Estado',
    city: 'Ciudad',
    addressNumber: 'Número',
  },
  PE:  {
    colony: 'Distrito',
    state: 'Departamento',
    city: 'Provincia',
    addressNumber: 'Número',
  },
  zipCode: 'Código postal',
  colony: 'Colonia',
  department: 'Departamento',
  township: 'Municipio',
  neighborhood: 'Barrio',
  state: 'Estado',
  city: 'Ciudad',
  reference: 'Indicaciones especiales para llegar a tu domicilio',
}

export default messages
