import React from 'react'
import style from './Success.module.css'
import logo from '../../Assets/images/svg/moons.svg'
import messages from './Success.messages'
import ProductInformation from '../ProductInformation/ProductInformation'
import AddressSaved from '../AddressSaved/AddressSaved'
import SuccessAddress from '../SuccessAddress/SuccessAddress'

const logoComponent = (
  <div className={style.Logo}>
    <img alt="logo" src={logo} />
  </div>
)

/**
 * Success Component
 * @param {Object} response - json to the response
 * @return {void}
 */
class Success extends React.Component {
  /**
   *
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.productInfo = window.innerWidth < 700
  }

  handleClick = () => {
    const { pseLink } = this.props
    pseLink && window.open(pseLink, '_blank')
  }

  /**
   * @returns {void}
   */
  render() {
    const {
      name,
      finalPrice,
      installmentsData,
      showAddress,
      address,
      showSend,
      currentProduct,
      currentExtraProducts,
      pricePrincipalProduct,
      dataExtra,
      pseLink,
      category,
      onChangeValue,
      customerId,
      paymentMethodSelected,
    } = this.props
    const title = pseLink
      ? messages.titlePSE.replace('{name}', name)
      : messages.title.replace('{name}', name)

    const pseButton = (
      <div
        role="button"
        tabIndex={0}
        className={style.SubmitButton}
        onClick={this.handleClick}
      >
        {messages.buttonPSE}
      </div>
    )
    return (
      <div className={style.SuccessContainer}>
        {logoComponent}
        <h1>{title}</h1>
        <h2>{messages.subTitle}</h2>
        <h2 className={style.Blue}>{messages.email}</h2>
        {pseLink && (
          <div className={style.PSEInfo}>
            <h2>{messages.pseText}</h2>
            {pseButton}
          </div>
        )}
        {this.productInfo && (
          <ProductInformation
            currentProduct={currentProduct}
            currentExtraProducts={currentExtraProducts}
            dataExtra={dataExtra}
            finalPrice={finalPrice}
            pricePrincipalProduct={pricePrincipalProduct}
            installmentsData={installmentsData}
            wasSendToBack
            showSend={showSend}
          />
        )}

        {address && showAddress && (
          <div className={style.AddressContainer}>
            <AddressSaved address={address} category={category} />
          </div>
        )}
        <SuccessAddress
          category={category}
          address={address}
          onChangeValue={onChangeValue}
          customerId={customerId}
          paymentMethodSelected={paymentMethodSelected}
        />
      </div>
    )
  }
}

export default Success
