const messages = {
  title: (paymentMethod) => `Acude a realizar el pago con ${capitalizeLetter(paymentMethod)} al punto de atención más cercano`,
  subTitle: 'Debes mostrar el siguiente código',
  textDownload: 'DESCARGAR FORMA DE PAGO',
  textSend: 'ENVIAR A MI CORREO',
}

const capitalizeLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1)

export default messages
