import React from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import ErrorPage from '@mymoons/error-page'
import Checkout from './Components/Checkout/Checkout'
import CheckoutFromPaymentId from './Components/CheckoutFromQuerys/CheckoutFromPaymentId'
import CheckoutFromPaymentLink from './Components/CheckoutFromQuerys/CheckoutFromPaymentLink'
import CheckoutOrganic from './Components/CheckoutFromQuerys/CheckoutOrganic'
import PSEResponse from './Components/PSEResponse/PSEResponse'
import Shell from './Components/Shell/Shell'
import Promo from './Components/Promo/Promo'
import { COUNTRY } from './Utils/constants'
import codeForCountry from './Utils/code-country'

const productsKeyName = [
  `organic-moons-kit-${codeForCountry(COUNTRY).toLowerCase()}`,
  `organic-advance-${codeForCountry(COUNTRY).toLowerCase()}`,
]

/**
 * Main app
 * @returns {React.Component} .
 */
const App = () => (
  <BrowserRouter>
    <Switch>
      <Route
        exact
        path="/checkout/paymentLink/:paymentKeyName/:salesAgentId?"
        component={() => (
          <Shell banner={Promo().isActive}>
            <CheckoutFromPaymentLink
              isAdvance={false}
              showPersonalData={false}
              showAddress
              showCoupon={false}
              extraProductsAllowed={false}
              showSend
              selector={1}
            />
          </Shell>
        )}
      />
      <Route
        exact
        path="/checkout/pseResponse/:name"
        component={() => (
          <Shell>
            <PSEResponse />
          </Shell>
        )}
      />
      <Route
        exact
        path="/checkout/:product/:paymentId/:salesAgentId?"
        component={() => (
          <Shell banner={Promo().isActive}>
            <CheckoutFromPaymentId
              isAdvance={false}
              showPersonalData={false}
              showAddress
              showCoupon
              extraProductsAllowed={false}
              showSend
              selector={1}
            />
          </Shell>
        )}
      />
      <Route
        exact
        path="/checkout/:product/:paymentId/cupon/:coupon/:salesAgentId?"
        component={() => (
          <Shell banner={Promo().isActive}>
            <CheckoutFromPaymentId
              isAdvance={false}
              showPersonalData={false}
              showAddress
              showCoupon
              extraProductsAllowed={false}
              showSend
              selector={1}
            />
          </Shell>
        )}
      />
      <Route
        exact
        path="/referral/:product/:paymentId/:coupon"
        component={() => (
          <Shell banner={Promo().isActive}>
            <CheckoutFromPaymentId
              isAdvance={false}
              showPersonalData={false}
              showAddress
              showCoupon
              extraProductsAllowed={false}
              showSend
              selector={1}
            />
          </Shell>
        )}
      />
      <Route
        exact
        path="/referral/:product/:paymentId/"
        component={() => (
          <Shell banner={Promo().isActive}>
            <CheckoutFromPaymentId
              isAdvance={false}
              showPersonalData={false}
              showAddress
              showCoupon
              extraProductsAllowed={false}
              showSend
              selector={1}
            />
          </Shell>
        )}
      />
      <Route
        exact
        path="/checkout/:coupon"
        component={() => (
          <Shell banner={Promo().isActive}>
            <CheckoutOrganic
              productsKeyName={productsKeyName}
              isAdvance={false}
              showPersonalData
              showAddress
              showCoupon
              extraProductsAllowed={false}
              showSend
              selector={1}
            />
          </Shell>
        )}
      />
      <Route
        path="/checkout"
        component={() => (
          <Shell banner={Promo().isActive}>
            <Checkout
              productsKeyName={productsKeyName}
              isAdvance={false}
              showPersonalData
              showAddress
              showCoupon={false}
              extraProductsAllowed={false}
              showSend
              selector={1}
            />
          </Shell>
        )}
      />
      <Route component={() => <ErrorPage />} />
    </Switch>
  </BrowserRouter>
)

export default App
