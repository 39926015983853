const messages = {
  title: '¡Gracias por tu compra, {name}!',
  titlePSE: '¡{name}, tu pago está siendo procesado!',
  subTitle: 'Si tienes dudas, escribenos a ',
  email: 'notifications@mymoons.mx',
  buttonPSE: 'Ir a PSE',
  pseText: 'Para concluir tu pago sigue los pasos de pse en el siguiente botón',
}

export default messages
