import React, { Component } from 'react'
import currency from 'currency.js'
import style from './TotalPrice.module.css'
import messages from './TotalPrice.messages'

/**
 * TotalPrice Component
 * @returns {void} .
 */
class TotalPrice extends Component {
  /**
   * TotalPrice constructor
   * @param {Object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    this.state = {}
  }

  /**
   * TotalPrice render
   * @returns {void} .
   */
  render() {
    const { price } = this.props

    const country = process.env.REACT_APP_COUNTRY
    const precision = country === 'Colombia' || country === 'Chile' || country === 'Peru' ? 0 : 2
    const separator = country === 'Colombia' || country === 'Chile' || country === 'Peru' ? '.' : ','
    const symbol = country === 'Peru' ? 'S/ ' : '$'
    const finalPrice = `${currency(price, { precision, separator, symbol }).format()}`
    const priceText = `${messages.total} ${finalPrice}`
    return (
      <div className={style.TotalPrice}>
        <div className={style.PriceContainer}>
          <p className={style.Iva}>{country === 'Peru' ? messages.igv : messages.iva}</p>
          <p className={style.Price}>{priceText}</p>
        </div>
      </div>
    )
  }
}

export default TotalPrice
