/* eslint-disable react/no-deprecated */
/* eslint-disable no-negated-condition */
import React, { Component, createRef } from 'react'
import currency from 'currency.js'
import styles from './ExtraProducts.module.css'
import messages from './ExtraProducts.messages'
import arrow from '../../Assets/images/svg/arrow-bold.svg'
import InputCounter from '../InputCounter/InputCounter'
import { dynamicRef } from '../../Utils/utils'

/**
 * ExtraProducts Component
 * @returns {void} .
 */
class ExtraProducts extends Component {
  /**
   * Constructor.
   * @param {object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)

    this.isSelector = window.innerWidth < 700
    this.state = {
      openDropDown: !this.isSelector,
    }

    this.refImg = createRef(null)
  }

  /**
   * Component Will Receive Props
   * @param {object} nextProps .
   * @returns {void} .
   */
  componentWillReceiveProps(nextProps) {
    const { products } = this.props
    if (nextProps.products.dataExtra !== products.dataExtra) {
      this.refCounter = dynamicRef(nextProps.products.dataExtra.length)
    }
  }

  OpenDropDown = () => {
    if (!this.isSelector) return
    const { openDropDown } = this.state
    this.refImg.current.style.transform = `rotate(${
      !openDropDown ? 180 : 0
    }deg)`
    this.setState({ openDropDown: !openDropDown })
  }

  /**
   * ExtraProducts render
   * @returns {void} .
   */
  render() {
    const { openDropDown } = this.state
    const { products, setProducts, currentExtraProducts } = this.props

    return (
      <div className={styles.ExtraContainer}>
        <div
          className={styles.SelectorWrapper}
          onClick={() => this.OpenDropDown()}
          role="button"
          tabIndex="0"
        >
          <div className={styles.TextTema}>
            {messages.title}
            <img
              ref={this.refImg}
              alt="arrow"
              src={arrow}
              className={styles.Arrow}
              style={{ transform: `rotate(${openDropDown ? 180 : 0}deg)` }}
            />
          </div>
        </div>
        {openDropDown &&
          Array.isArray(products.dataExtra) &&
          products.dataExtra.map((product) => {
            const defaultValue =
              currentExtraProducts && currentExtraProducts[product.keyName]
                ? currentExtraProducts[product.keyName].quantity
                : 0
            return (
              <Item
                key={product.objectId}
                product={product}
                setProducts={setProducts}
                defaultValue={defaultValue}
              />
            )
          })}
      </div>
    )
  }
}

/**
 * Item.
 * @param {object} product .
 * @returns {void} .
 */
const Item = ({ product, setProducts, defaultValue }) => {
  const country = process.env.REACT_APP_COUNTRY
  const precision =
    country === 'Colombia' || country === 'Chile' || country === 'Peru' ? 0 : 2
  const separator =
    country === 'Colombia' || country === 'Chile' || country === 'Peru' ? '.' : ','
  const symbol = country === 'Peru' ? 'S/ ' : '$'
  const price = `${currency(product.finalPrice, {
    precision,
    separator,
    symbol
  }).format()}`
  const imageProduct = product && product.imageUrl

  return (
    <div className={styles.ItemContainer}>
      <div className={styles.Left}>
        <img alt="prod" src={imageProduct} className={styles.ImageProducts} />
        <div className={styles.TitlePrice}>
          <p className={styles.NameProduct}>{product.name}</p>
          <div className={styles.Price}>
            {messages.price}
            <br />
            {price}
          </div>
        </div>
      </div>
      <div className={styles.Rigth}>
        <InputCounter
          default={defaultValue}
          keyName={product.keyName}
          setQuantity={setProducts}
        />
      </div>
    </div>
  )
}

export default ExtraProducts
