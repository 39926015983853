const messages = {
  titleSteps: [
    'Datos personales',
    '¿Dónde enviaremos tus moons cuando estén listos?',
    'Método de Pago',
  ],
  titleCheckout: 'Proporciona tu dirección de facturación',
  name: 'Nombre',
  movil: 'Teléfono',
  email: 'Correo electrónico',
  street: 'Calle',
  internalNumber: 'Número Interior',
  CO: {
    colony: 'Barrio',
    state: 'Departamento',
    city: 'Municipio',
    addressNumber: 'Número',
  },
  CL: {
    // There isn't colony in Chile for the moment
    colony: 'TBD',
    state: 'Región',
    city: 'Comuna',
    addressNumber: 'Número Exterior',
  },
  MX: {
    colony: 'Colonia',
    state: 'Estado',
    city: 'Ciudad',
    addressNumber: 'Número',
  },
  PE: {
    colony: 'Distrito',
    state: 'Departamento',
    city: 'Provincia',
    addressNumber: 'Número',
  },
  zipCode: 'Código postal',
  colony: 'Colonia',
  department: 'Departamento',
  for: 'para',
  township: 'Municipio',
  neighborhood: 'Barrio',
  state: 'Estado',
  city: 'Ciudad',
  reference: 'Indicaciones especiales para llegar a tu domicilio',
  card: 'Número de tarjeta',
  cardName: 'Titular de la tarjeta',
  month: 'Mes (MM)',
  year: 'Año (YY)',
  cvc: 'CVC',
  textInst: {
    MX: 'Esta tarjeta no acepta diferir a meses',
    CO: 'Esta tarjeta no acepta diferir a cuotas',
    CL: 'Esta tarjeta no acepta diferir a cuotas',
    PE: 'Esta tarjeta no acepta diferir a cuotas',
  },
}

export default messages
