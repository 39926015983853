const { REACT_APP_COUNTRY } = process.env

export const INITIAL_STEP = 'initial'
export const PERSONAL_DATA_STEP = 'personalData'
export const ADDRESS_STEP = 'address'
export const PAYMENTS_STEP = 'payments'
export const COUNTRY = REACT_APP_COUNTRY
export const IS_MEXICO = REACT_APP_COUNTRY === 'México'
export const IS_COLOMBIA = REACT_APP_COUNTRY === 'Colombia'
export const IS_CHILE = REACT_APP_COUNTRY === 'Chile'
export const IS_PERU = REACT_APP_COUNTRY === 'Peru'
