import React from 'react'
import styles from './FullModal.module.css'

/**
 * Full Modal Component
 * @param {Object} props .
 * @return {void}
 */
const FullModalContainer = (props) => {
  const { open, content } = props
  const Content = content
  return (
    <>{open ? <div className={styles.PopUpFullModal}>{Content}</div> : ''}</>
  )
}

export default FullModalContainer
