export const displayWidget = (attributes) => {
  try {
    window.MessageBirdChatWidget.setAttributes({
      pageLoad: attributes,
    })
    console.log('attributes: ', window.MessageBirdChatWidget)
    window.MessageBirdChatWidget.show()
  } catch (error) {
    console.log('error: ', error)
  }
}
