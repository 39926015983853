/* eslint-disable max-statements */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable react/jsx-no-literals */
/* eslint-disable complexity */
import React, { Component } from 'react'
import currency from 'currency.js'
import styles from './ProductInformation.module.css'
import messages from './ProductInformation.messages'
import Coupon from '../Coupon/Coupon'
import forbes from '../../Assets/images/png/forbes.png'
import { chooseCountry } from '../../Utils/utils'

/**
 * ProductInformation Component
 * @returns {void} .
 */
class ProductInformation extends Component {
  /**
   * ProductInformation constructor
   * @param {Object} props .
   * @returns {void} .
   */
  constructor(props) {
    super(props)
    this.state = {}
  }

  /**
   * Set Extra Data
   * @param {String} index .
   * @returns {void} .
   */
  setExtraData = (index) => {
    const { setExtraProducts, currentExtraProducts } = this.props
    const { quantity } = currentExtraProducts[index]
    const newExtra = { keyName: index, quantity: quantity - 1 }
    setExtraProducts && setExtraProducts(newExtra)
  }

  extraProductsComponent = () => {
    const { currentExtraProducts, dataExtra } = this.props
    const component = []
    const country = process.env.REACT_APP_COUNTRY
    for (const index in currentExtraProducts) {
      if ({}.hasOwnProperty.call(currentExtraProducts, index)) {
        const extra = dataExtra.filter(
          (dataExt) => dataExt.keyName === index
        )[0]
        const precision = country === 'Colombia' || country === 'Chile' || country === 'Peru' ? 0 : 2
        const separator =
          country === 'Colombia' || country === 'Chile' || country === 'Peru' ? '.' : ','
        const symbol = country === 'Peru' ? 'S/ ' : '$'
        const priceExtra = `${currency(extra.finalPrice, {
          precision,
          separator,
          symbol
        }).format()} ${extra.currency}`

        let { quantity } = currentExtraProducts[index]
        while (quantity) {
          component.push(
            <div
              key={`${extra.objectId}_${quantity}`}
              className={styles.TitlePriceExtra}
            >
              <div className={styles.PriceExtra}>
                <p className={styles.NameProduct}>{extra && extra.name}</p>
                <div
                  tabIndex="0"
                  role="button"
                  className={styles.DeleteProduct}
                  onClick={() => this.setExtraData(index)}
                >
                  {messages.close}
                </div>
              </div>

              <div className={styles.PriceExtra}>
                <p className={styles.TagPrice}>{messages.price}</p>
                <p className={styles.NumberPrice}>{priceExtra}</p>
              </div>
              <hr className={styles.Separator} />
            </div>
          )
          quantity -= 1
        }
      }
    }
    return component
  }

  /**
   * Component Render
   * @return {void} .
   */
  render() {
    const {
      currentProduct,
      currentExtraProducts,
      couponData,
      finalPrice,
      showSend,
      pricePrincipalProduct,
      isPaymentLink,
      installmentsData,
      wasSendToBack,
      isLiquidation,
      patientName,
      isSixtyForty,
      urlCoupon,
    } = this.props

    const country = process.env.REACT_APP_COUNTRY
    const { priceInstallments } = installmentsData || {}
    const currencyProd = `${currentProduct ? currentProduct.currency : ''}`
    const precision = country === 'Colombia' || country === 'Chile' || country === 'Peru' ? 0 : 2
    const separator = country === 'Colombia' || country === 'Chile' || country === 'Peru' ? '.' : ','
    const symbol = country === 'Peru' ? 'S/ ' : '$'
    const priceTotal = `${currency(priceInstallments || finalPrice, {
      precision,
      separator,
      symbol
    }).format()}`
    // eslint-disable-next-line no-unused-vars
    const priceProduct = currentProduct
      ? `${currency(pricePrincipalProduct || currentProduct.finalPrice, {
          precision,
          separator,
          symbol
        }).format()} ${currencyProd}`
      : 0

    const price = (currentProduct && currentProduct.price) || 0
    const onlyPrice = currentProduct
      ? `${currency(price, { precision, separator, symbol }).format()}`
      : 0

    const countryCode = chooseCountry(country)
    const priceSend = `${currency(messages.priceSend[countryCode], {
      precision: 0,
      separator,
      symbol
    }).format()}`
    let savedPrice = price > finalPrice ? price - finalPrice : 0
    savedPrice = `${currency(savedPrice, {
      precision,
      separator,
      symbol
    }).format()} ${country === 'Peru' ? '' : currencyProd}`

    const nameProduct =
      currentProduct && (currentProduct.keyName || currentProduct.paymentKey)

    const imageProduct = currentProduct && currentProduct.imageUrl

    const showCoupon =
      !isPaymentLink && couponData && couponData.showCoupon && !wasSendToBack

    let titleProduct = currentProduct && `${currentProduct.description} `
    if (patientName) {
      titleProduct =
        currentProduct &&
        `${currentProduct.description} ${messages.for} ${patientName}`
    }

    if (isSixtyForty) {
      titleProduct =
        currentProduct &&
        `${currentProduct.financingText} ${messages.for} ${patientName}`
    } else if (isLiquidation) {
      titleProduct = currentProduct && currentProduct.payoffText
    }
    return (
      <div className={styles.InfoContainer}>
        <img alt="forbes" src={forbes} className={styles.ForbesImage} />
        <div className={styles.Wrapper}>
          <div className={styles.TextTitle}>{messages.titleInfo}</div>
          <div className={styles.PrincipalProduct}>
            {imageProduct ? (
              <img
                alt="prod"
                src={imageProduct}
                className={[
                  imageProduct ? '' : styles.ImageProducts,
                  styles.MarginImage,
                ].join(' ')}
              />
            ) : (
              <div className={styles.ImageProducts} />
            )}
            <div className={styles.TitlePrice}>
              <p className={styles.NameProduct}>{titleProduct}</p>
              {!isSixtyForty && (
                <div className={styles.FirstPrice}>
                  <p className={styles.TagPrice}>{messages.price}</p>
                  <p className={styles.NumberPrice}>
                    <strike>{onlyPrice}</strike>
                    {country === 'Peru' ? '' : currencyProd}
                  </p>
                </div>
              )}
              {!isSixtyForty && (
                <div className={styles.Price}>
                  <p className={styles.TagPrice}>{messages.saved}</p>
                  <p className={styles.NumberPrice}>{savedPrice}</p>
                </div>
              )}
            </div>
          </div>
          <hr className={styles.Separator} />
          {currentExtraProducts && this.extraProductsComponent()}
          {showCoupon && (
            <Coupon
              couponData={couponData}
              nameProduct={nameProduct}
              urlCoupon={urlCoupon}
            />
          )}
          {showSend && (
            <>
              <div className={styles.Send}>
                <div className={styles.SendItemFirst}>
                  <p className={styles.TextItem}>{messages.subtotal}</p>
                  <p
                    className={styles.TextItem}
                  >{`${priceTotal} ${country === 'Peru' ? '' : currencyProd}`}</p>
                </div>
                <div className={styles.SendItem}>
                  <p className={styles.TextItem}>{messages.send}</p>
                  <p className={styles.TextItem}>
                    <strike>{priceSend}</strike>
                    {messages.free}
                  </p>
                </div>
              </div>
              <hr className={styles.Separator} />
            </>
          )}
          <div className={styles.TotalPrice}>
            <p className={[styles.TextItemBold, styles.TotalText].join(' ')}>
              {messages.total}
            </p>
            <div className={styles.RightPrice}>
              {country !== 'Peru' && (
                <p className={[styles.TextItem, styles.Currency].join(' ')}>
                {currencyProd}
              </p>
              )}
              <p
                id="total_price"
                className={[styles.TextItemBold, styles.TotalNumber].join(' ')}
              >
                {priceTotal}
              </p>
            </div>
          </div>
          <p className={styles.Iva}>{country === 'Peru' ? messages.igv : messages.iva}</p>
          {currentProduct &&
            currentProduct.keyName &&
            !currentProduct.keyName.includes('homekit') &&
            !currentProduct.keyName.includes('appointment') && (
              <p className={styles.Iva}>{messages.devolution}</p>
            )}
        </div>
      </div>
    )
  }
}

export default ProductInformation
