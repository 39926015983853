const referralData = {
  mx: {
    payoff: {
      title: 'Hoy diste el primer paso a una sonrisa Moons',
      callToAction: '¡Gana hasta $1,500 en Amazon!',
      description:
        'En Moons, nuestra mejor publicidad son nuestros clientes, invita a un amigo a que empiece también su proceso con Moons, compartiéndole tu liga personalizada.',
      buttonText: 'Quiero participar',
      domain: process.env.REACT_APP_REFERRAL_URL_MX,
      linkText:
        '¡Hola, si quieres alinearte los dientes sin brackets con moons como yo, te cuento que moons tiene una promoción para referidos de $1,000 pesos extra de descuento en tu tratamiento. Es el descuento más grande que hay en este momento, aprovecha quedan solo pocos días… ¡Solo regístrate y paga tu anticipo en esta liga :)! ',
      platformCode: process.env.REACT_APP_PLATFORM_CODE_MX_PAYOFF,
    },
    advance: {
      title: 'Hoy diste el primer paso a una sonrisa Moons',
      callToAction: '¡Gana hasta $1,500 en Amazon!',
      description:
        'En Moons, nuestra mejor publicidad son nuestros clientes, invita a un amigo a que empiece también su proceso con Moons, compartiéndole tu liga personalizada.',
      buttonText: 'Quiero participar',
      domain: process.env.REACT_APP_REFERRAL_URL_MX,
      linkText:
        '¡Hola, acabo de agendar mi cita de valoración de sonrisa moons, si quieres alinearte los dientes sin tener que usar brackets, con moons como yo. Tienes el mejor descuento por ser mi referido. ¡Solo regístrate en mi liga especial. ',
      platformCode: process.env.REACT_APP_PLATFORM_CODE_MX_ADVANCE,
    },
  },
  co: {
    payoff: {
      title: 'Hoy diste el primer paso a una sonrisa Moons',
      callToAction: '¡Gana hasta $250,000 en Falabella!',
      description:
        'En Moons, nuestra mejor publicidad son nuestros clientes, invita a un amigo a que empiece también su proceso con Moons, compartiéndole tu liga personalizada.',
      buttonText: 'Quiero participar',
      domain: process.env.REACT_APP_REFERRAL_URL_CO,
      linkText: '',
      platformCode: process.env.REACT_APP_PLATFORM_CODE_CO,
    },
    advance: {
      title: 'Hoy diste el primer paso a una sonrisa Moons',
      callToAction: '¡Gana hasta $250,000 en Falabella!',
      description:
        'En Moons, nuestra mejor publicidad son nuestros clientes, invita a un amigo a que empiece también su proceso con Moons, compartiéndole tu liga personalizada.',
      buttonText: 'Quiero participar',
      domain: process.env.REACT_APP_REFERRAL_URL_CO,
      linkText:
        '¡Hola, si quieres alinearte los dientes sin brackets con moons como yo, te cuento que moons tiene una promoción para referidos de $160,000 pesos extra de descuento en tu tratamiento. Es el descuento más grande que hay en este momento, aprovecha quedan solo pocos días… ¡Solo regístrate y paga tu anticipo en esta liga :)! ',
      platformCode: process.env.REACT_APP_PLATFORM_CODE_CO,
    },
  },
}

/**
 * getReferralData
 * @param {String} countryCode .
 * @param {String} type .
 * @returns {object} .
 */
export const getReferralData = (
  countryCode,
  type,
  referralCode,
  patientName
) => {
  const popupData =
    referralData[countryCode] && referralData[countryCode][type]
      ? referralData[countryCode][type]
      : referralData['mx']['advance']
  const link = getLink(
    popupData.domain,
    popupData.platformCode,
    referralCode,
    patientName,
    popupData.linkText
  )
  return { popupData, link }
}

/**
 * getReferralData
 * @param {String} countryCode .
 * @param {String} type .
 * @returns {object} .
 */
export const getLink = (domain, code, referralCode, patientName, linkText) => {
  const name = patientName ? patientName.split(' ')[0] : 'Referido'
  let referralLink = `${domain}/?utm_source=${code}&referral=${referralCode}&name=${name}`
  referralLink = encodeURIComponent(referralLink)
  let link = `https://api.whatsapp.com/send/?phone&text=${linkText} `
  link = encodeURI(link)
  link += referralLink
  return link
}
