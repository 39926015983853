const messages = {
  textButton: 'CONTINUAR',
  OxxoLabel: 'Obtener referencia',
  CardLabel: 'Pagar',
  PseLabel: 'Pagar',
  EfectyLabel: 'Pagar',
  BalotoLabel: 'Pagar',
  BankTransferLabel: 'Obtener CLABE',
  PagoEfectivoLabel: 'Obtener referencia'
}

export default messages
