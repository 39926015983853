/* eslint-disable react/jsx-no-literals */
import React from 'react'
import style from './Footer.module.css'
import messages from './Footer.messages'
import garanty from '../../Assets/images/svg/garanty.svg'
import saveSend from '../../Assets/images/svg/saveSend.svg'
import badge from '../../Assets/images/png/co-sticker.png'
import { chooseCountry } from '../../Utils/utils'

/**
 * Footer Component
 * @returns {React.Component} .
 */
const Footer = ({ country }) => {
  const urlTerm = messages.urlTerm[country]
  const urlPrivacy = messages.urlPrivacy[country]
  const countryCode = chooseCountry(country)
  const promo = {
    mx: {
      active: false,
      isCurrent: countryCode === 'MX',
    },
    co: {
      active: false,
      isCurrent: countryCode === 'CO',
    },
  }
  let poweredBy = <b className={style.Bold}>{messages.stripe}</b>

  if(country != 'México'){
    poweredBy = <b className={style.Bold}>{messages.mercadoPago}</b>
  }
  
  promo.isActive =
    (promo.mx.isCurrent && promo.mx.active) ||
    (promo.co.isCurrent && promo.co.active)
  return (
    <div className={style.FooterContainer}>
      <div className={style.ImagesContainer}>
        <div
          className={`mfes-trustmark ${style.McAffe}`}
          data-type="102"
          data-width="110"
          data-height="50"
        />
        <img className={style.GarantyImages} alt="garanty" src={garanty} />
        <img className={style.SendImages} alt="saveSend" src={saveSend} />
        {promo.isActive && (
          <img className={style.Badge} alt="hot sale" src={badge} />
        )}
      </div>
      <div className={style.LinksContainer}>
        <p className={style.ItemStripe}>
          {messages.by}
          {poweredBy}
        </p>
        <div className={style.LinksLeft}>
          <a
            href={urlPrivacy}
            rel="noopener noreferrer"
            target="_blank"
            className={style.ItemPrivacy}
          >
            {messages.privacy}
          </a>
          <a
            href={urlTerm}
            rel="noopener noreferrer"
            target="_blank"
            className={style.ItemTerm}
          >
            {messages.term}
          </a>
        </div>
        <div className={style.ItemLove}>
          <p>{messages.textLove[countryCode]}</p>
        </div>
      </div>
    </div>
  )
}

export default Footer
