/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable no-debugger */
import Parse from 'parse'
import {
  chooseCountry,
  setAnalyticsUserId,
  setTrackingPatient,
} from '../Utils/utils'

Parse.serverURL = process.env.REACT_APP_SERVER_URL
Parse.initialize(
  process.env.REACT_APP_APPLICATION_ID,
  process.env.REACT_APP_JAVASCRIPT_KEY,
  process.env.REACT_APP_MASTER_KEY
)

const Services = {
  /**
   * Get transaction log by id.
   * @param {string} id .
   * @returns {void} .
   */
  getConektaOxxoById: async (id) => {
    const ConektaOxxo = Parse.Object.extend('ConektaOxxo')
    const query = new Parse.Query(ConektaOxxo)

    query.equalTo('Payment_Id', id)
    const response = await query.first().then((results) => {
      let data = null
      if (results) {
        data = JSON.stringify(results)
      }
      return data
    })
    return JSON.parse(response)
  },

  /**
   * Get transaction log by id.
   * @param {string} name .
   * @returns {void} .
   */
  hasInstallmentsByName: async (name) => {
    const Product = Parse.Object.extend('Product')
    const query = new Parse.Query(Product)

    query.equalTo('keyName', name)
    const response = await query.first().then((results) => {
      let data = null
      let installments = false
      let installmentsPrice = null
      if (results) {
        data = JSON.stringify(results)
        installments = JSON.parse(data).installments
        installmentsPrice = JSON.parse(data).installmentsPrice
      }
      return { installments, installmentsPrice }
    })
    return response
  },

  /**
   * Get the product by its keyname
   * @param {String} keyName - keyname of the product
   * @return {void} .
   */
  getProductByKeyName: async (keyName) => {
    try {
      const Product = Parse.Object.extend('Product')
      const query = new Parse.Query(Product)
      query.equalTo('keyName', keyName)
      const product = await query.first()
      let dataProduct = null
      if (product) {
        const data = JSON.stringify(product)
        dataProduct = JSON.parse(data)
        return dataProduct
      }
      return product
    } catch (errorParse) {
      return { error: true, errorParse }
    }
  },

  /**
   * Get Extra Products Active
   * @return {void} .
   */
  getExtraProductsActive: async () => {
    try {
      const Product = Parse.Object.extend('Product')
      const query = new Parse.Query(Product)
      query.equalTo('type', 'extra')
      query.equalTo('active', true)
      const products = await query.find()
      let dataProduct = null
      if (products) {
        const data = JSON.stringify(products)
        dataProduct = JSON.parse(data)
        return dataProduct
      }
      return products
    } catch (errorParse) {
      return { error: true, errorParse }
    }
  },

  /**
   * Get the paymentLink by its keyname
   * @param {String} keyName - keyname of the paymentLink
   * @return {void} .
   */
  getPaymentLinkByKeyName: async (keyName) => {
    try {
      const PaymentLink = Parse.Object.extend('PaymentLink')
      const query = new Parse.Query(PaymentLink)
      query.equalTo('paymentKey', keyName)
      const paymentLink = await query.first()
      let dataProduct = null
      if (paymentLink) {
        const data = JSON.stringify(paymentLink)
        dataProduct = JSON.parse(data)
        return dataProduct
      }
      return paymentLink
    } catch (errorParse) {
      return { error: true, errorParse }
    }
  },

  /**
   * Get Patient By CustomerId
   * @param {String} customerId - customer id
   * @return {void} .
   */
  getPatientByCustomerId: async (customerId) => {
    try {
      const Patient = Parse.Object.extend('Patient')
      const query = new Parse.Query(Patient)
      query.equalTo('CustomerId', customerId)
      const patient = await query.first()
      let dataPatient = null
      if (patient) {
        const data = JSON.stringify(patient)
        dataPatient = JSON.parse(data)
        return dataPatient
      }
      return patient
    } catch (errorParse) {
      return { error: true, errorParse }
    }
  },

  getPatientByPaymentId: async (paymentId) => {
    try {
      const Patient = Parse.Object.extend('Patient')
      const query = new Parse.Query(Patient)
      query.equalTo('PaymentId', paymentId)
      const patient = await query.first()
      let dataPatient = null
      if (patient) {
        const data = JSON.stringify(patient)
        dataPatient = JSON.parse(data)
        const customerId = dataPatient.CustomerId
          ? dataPatient.CustomerId
          : false
        setTrackingPatient(dataPatient)
        setAnalyticsUserId(customerId)
        return dataPatient
      }
      return dataPatient
    } catch (errorParse) {
      return { error: true, errorParse }
    }
  },

  getCoupon: async (code, productKeyName) => {
    try {
      const Coupon = Parse.Object.extend('Coupon')
      const query = new Parse.Query(Coupon)
      query.equalTo('Code', code)
      const coupon = await query.first()
      const country = process.env.REACT_APP_COUNTRY
      const countryCode = chooseCountry(country)
      let dataCoupon = null
      if (coupon) {
        const data = JSON.stringify(coupon)
        dataCoupon = JSON.parse(data)

        if (
          !dataCoupon.Apply_Total &&
          dataCoupon.Discount_Type === 'percentage'
        ) {
          return null
        }

        if (dataCoupon.Product_Restriction) {
          if (
            !dataCoupon.Product_Restriction.includes(productKeyName) &&
            !dataCoupon.Product_Restriction.includes('all')
          ) {
            return null
          }
        } else {
          return null
        }

        if (dataCoupon.Country_Restriction) {
          if (
            !dataCoupon.Country_Restriction.includes(country) &&
            !dataCoupon.Country_Restriction.includes(country.toLowerCase()) &&
            !dataCoupon.Country_Restriction.includes(countryCode) &&
            !dataCoupon.Country_Restriction.includes(countryCode.toLowerCase())
          ) {
            return null
          }
        } else {
          return null
        }
      }
      return dataCoupon
    } catch (errorParse) {
      return { error: true, errorParse }
    }
  },

  getReferralCoupon: async (code, productKeyName) => {
    try {
      const referralCoupon = Parse.Object.extend('ReferralCoupon')
      const query = new Parse.Query(referralCoupon)
      query.equalTo('Code', code)
      const coupon = await query.first()
      const country = process.env.REACT_APP_COUNTRY
      const countryCode = chooseCountry(country)
      let dataCoupon = null
      if (coupon) {
        const data = JSON.stringify(coupon)
        dataCoupon = JSON.parse(data)
        dataCoupon.isReferralCoupon = true
        if (dataCoupon.Product_Restriction) {
          if (
            !dataCoupon.Product_Restriction.includes(productKeyName) &&
            !dataCoupon.Product_Restriction.includes('all')
          ) {
            return null
          }
        } else {
          return null
        }

        if (dataCoupon.Country_Restriction) {
          if (
            !dataCoupon.Country_Restriction.includes(country) &&
            !dataCoupon.Country_Restriction.includes(country.toLowerCase()) &&
            !dataCoupon.Country_Restriction.includes(countryCode) &&
            !dataCoupon.Country_Restriction.includes(countryCode.toLowerCase())
          ) {
            return null
          }
        } else {
          return null
        }
      }
      return dataCoupon
    } catch (errorParse) {
      return { error: true, errorParse }
    }
  },

  getPatientByPaymentLink: async (paymentCode) => {
    try {
      const PaymentLink = Parse.Object.extend('PaymentLink')
      const query = new Parse.Query(PaymentLink)
      query.equalTo('paymentKey', paymentCode)
      query.include('patient')
      const paymentLink = await query.first()
      let dataPaymentLink = null
      if (paymentLink) {
        const data = JSON.stringify(paymentLink)
        dataPaymentLink = JSON.parse(data)
        setTrackingPatient(dataPaymentLink.patient)
        setAnalyticsUserId(dataPaymentLink.customerId)

        return {
          name: dataPaymentLink.name,
          phone: dataPaymentLink.phone,
          email: dataPaymentLink.email,
          customerId: dataPaymentLink.customerId,
          country: dataPaymentLink.countryOps,
          patientCountry:
            dataPaymentLink.patient && dataPaymentLink.patient.Country_Ops,
          Referral_Code:
            dataPaymentLink.patient && dataPaymentLink.patient.Referral_Code,
        }
      }
      return paymentLink
    } catch (errorParse) {
      return { error: true, errorParse }
    }
  },

  getAffiliateByCode: async (code) => {
    try {
      const Affiliate = Parse.Object.extend('Affiliate')
      const query = new Parse.Query(Affiliate)
      query.equalTo('Content_Code', code)
      const affiliate = await query.first()
      let dataAffiliate = null
      if (affiliate) {
        const data = JSON.stringify(affiliate)
        dataAffiliate = JSON.parse(data)
        return dataAffiliate
      }
      return affiliate
    } catch (errorParse) {
      return { error: true, errorParse }
    }
  },

  saveAbandonedCard: async (data, steps) => {
    try {
      const AbandonedCart = Parse.Object.extend('AbandonedCart')
      const query = new Parse.Query(AbandonedCart)
      query.equalTo('Email', data.email)

      const row = await query.first()
      if (row && (data.attempts || data.nameStep)) {
        Services.updateAbandonedCard(row, data, steps)
      } else {
        Services.addAbandonedCard(AbandonedCart, data)
      }
    } catch (error) {
      return { error }
    }
  },

  addAbandonedCard: (AbandonedCart, data) => {
    try {
      const query = new AbandonedCart()

      query.set('Patient_Name', data.name)
      query.set('Email', data.email)
      query.set('Phone', data.number)
      query.set('Paid', false)
      query.set('Attempts', data.attempts)
      query.set('Step', data.nameStep)

      query.save().then(
        (result) => result,
        (error) => {
          throw error
        }
      )
    } catch (errorParse) {
      return { errorParse }
    }
  },

  updateAbandonedCard: (query, data, steps) => {
    try {
      if (data.attempts && data.newFlow) {
        let attempts = query.get('Attempts')
        attempts += data.attempts
        query.set('Attempts', attempts)
      }
      if (data.nameStep) {
        const oldNameStep = query.get('Step')
        const oldIndex = steps.findIndex((step) => step.name === oldNameStep)
        const newIndex = steps.findIndex((step) => step.name === data.nameStep)
        if (newIndex > oldIndex) query.set('Step', data.nameStep)
      }

      data.name && query.set('Patient_Name', data.name)
      data.number && query.set('Phone', data.number)

      query.save().then(
        (result) => result,
        (error) => {
          throw error
        }
      )
    } catch (errorParse) {
      return { errorParse }
    }
  },

  deleteAbandonedCard: async (email) => {
    try {
      const AbandonedCart = Parse.Object.extend('AbandonedCart')
      const query = new Parse.Query(AbandonedCart)
      query.equalTo('Email', email)
      const row = await query.first()

      row &&
        row.destroy().then(
          (result) => result,
          (error) => {
            throw error
          }
        )
    } catch (errorParse) {
      return { errorParse }
    }
  },
}

export default Services
