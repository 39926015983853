/* eslint-disable complexity */
import React from 'react'
import styles from './SuccessAddress.module.css'
import messages from './SuccessAddress.messages'
import Address from '../Address/Address'
import { chooseCountry } from '../../Utils/utils'
import sentToCRM from '../../Apis/BlackBox'
import LoaderSection from '../LoaderSection/LoaderSection'
import validator from '../../Utils/validatorForm'
import codeForCountry from '../../Utils/code-country'

/**
 * SuccessAddress Component
 * @return {void}
 */
class SuccessAddress extends React.Component {
  /**
   *
   * @param {*} props .
   */
  constructor(props) {
    super(props)

    this.state = {
      show: true,
      successText: '',
      loader: false,
      addressError: {},
      error: false,
      showform: props.paymentMethodSelected === 'card',
    }

    this.country = process.env.REACT_APP_COUNTRY
    this.countryCode = chooseCountry(this.country).toLowerCase()
  }

  submitData = () => {
    const { address, customerId } = this.props
    const error = validator.validateAddress(address)
    if (error && error.addressError) {
      return this.setState({ addressError: { ...error.addressError } })
    }

    this.setState({
      loader: true,
      show: false,
      successText: false,
      addressError: {},
    })
    const data = {
      address,
      patient: { customerId },
    }
    sentToCRM(data, this.handleSubmit, this.handleError)
  }

  handleSubmit = () => {
    this.setState({
      successText: messages.successText,
      show: false,
      loader: false,
    })
  }

  /**
   * HandleError
   * @param {String} error .
   * @returns {void}
   */
  handleError = (error) => {
    this.setState({
      successText: error.message,
      loader: false,
      show: true,
      error: true,
    })
  }

  /**
   * @returns {void}
   */
  render() {
    const { category, address, onChangeValue, paymentMethodSelected } =
      this.props

    const { show, successText, loader, addressError, error, showform } =
      this.state

    const showConfirmAddress =
      show && (category === 'appointment' || category === 'moons')

    const titleAddressComponent =
      paymentMethodSelected === 'card' ? (
        <p className={styles.TitleAddress}>{messages.titleAddress2}</p>
      ) : (
        <div
          className={styles.TitleAddressLink}
          role="button"
          tabIndex={0}
          onClick={() => this.setState({ showform: !showform })}
        >
          {messages.titleAddress1}
        </div>
      )
    const codeCountry = codeForCountry(this.country)
    return (
      <div className={styles.SuccessContainer}>
        {loader && (
          <div className={styles.LoaderError}>
            <LoaderSection />
          </div>
        )}
        {successText && (
          <p
            className={[
              styles.TitleAddressSuccess,
              error ? styles.Error : '',
            ].join(' ')}
          >
            {successText}
          </p>
        )}
        {showConfirmAddress && address && (
          <div className={styles.AddressContainer}>
            {titleAddressComponent}
            {showform && (
              <>
                <Address
                  errorInternalNumber={
                    addressError && addressError.internalNumberError
                  }
                  errorStreet={addressError && addressError.streetError}
                  errorAddressNumber={
                    addressError && addressError.addressNumberError
                  }
                  errorZipCode={addressError && addressError.zipCodeError}
                  errorColony={addressError && addressError.colonyError}
                  errorState={addressError && addressError.stateError}
                  errorCity={addressError && addressError.cityError}
                  errorReference={addressError && addressError.referenceError}
                  streetTextLabel={messages.street}
                  internalNumberTextLabel={messages.internalNumber}
                  addressNumberTextLabel={messages[codeCountry].addressNumber}
                  zipCodeTextLabel={messages.zipCode}
                  colonyTextLabel={messages[codeCountry].colony}
                  stateTextLabel={messages[codeCountry].state}
                  cityTextLabel={messages[codeCountry].city}
                  referenceTextLabel={messages.reference}
                  address={address}
                  country={this.country}
                  onChangeValue={onChangeValue}
                />
                <div className={styles.FormSubmitButton}>
                  <div
                    role="button"
                    tabIndex={0}
                    className={styles.SubmitButton}
                    onClick={() => this.submitData()}
                  >
                    {messages.save}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default SuccessAddress
